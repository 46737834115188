import { IonIcon } from "@ionic/react";
import { trashBinOutline } from "ionicons/icons";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { devices } from "../../Devices";

const Container = styled.div`
  /background-color: grey;
  padding: 1.5rem 0;
  //box-shadow: 0px 0px 10px 5px rgba(1, 1, 1, 0.1);
`;

const EncarteStyle = styled.div`
  width: 15rem;
  height: 18rem;
  align-self: end;
  //box-shadow: 0px 15px 30px rgba(1, 1, 1, 0.1);
  cursor: pointer;
  transition: transform 300ms ease-in-out;
  &:hover {
    &:after {
      opacity: 1;
    }
  }
  position: relative;
  .icon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 2rem;
    color: #fff;
  }

  .icon:hover {
    color: red;
  }

  ${({ url, name }) => {
    return css`
      background-image: url("${url}");
      background-size: cover;

      &::after {
        transition: all 200ms ease-in;
        width: 100%;
        content: "${name}";
        text-align: center;
        top: 100%;
        position: absolute;
        transform: translateY(-100%);
        background-color: rgba(1, 1, 1, 0.5);
        opacity: 1;
        padding: 1rem 0;
        color: #fff;
        font-size: 1.2rem;
        text-transform: capitalize;
        line-height: 1.3;
      }
    `;
  }}
  @media ${devices.tablet} {
    width: 15rem;
    height: 18rem;
  }
`;
export default function EncarteCard({ url, name, onClick }) {
  return (
    <div onClick={onClick}>
      <EncarteStyle
        className="border-radius"
        url={url}
        name={name}
      ></EncarteStyle>
    </div>
  );
}
