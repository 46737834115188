import React from "react";
import Button from "./ActionButton";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { devices } from "../../Devices";
import { IonIcon } from "@ionic/react";
import { checkmark, closeOutline } from "ionicons/icons";

const Container = styled.div`
  gap: 8rem;
  @media ${devices.tablet} {
    gap: 3.6rem;

    .plans-wrapper {
    gap: 5.4rem !important;
    }
    .main-price {
      margin-top: 4.2rem;
    }
  }
  .title {
    font-size: 3rem;
    text-align: center;
  }

  .plan-description {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
  .plans-wrapper {
    width: 100%;
    gap: 4.2rem;
    height: 100%;
    justify-content: center;
    align-items: center;
    @media ${devices.tablet} {
      flex-direction: column; !important;
      
      .semestral {
        margin-top: 4.2rem;
      }
    }
  }
`;
const PlanCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(1, 1, 1, 0.15);
  padding: 2rem 3rem;
  position: relative;
  width: 25rem;
  h1 {
    font-size: 2.2rem;
    text-transform: capitalize;
    font-weight: 600;
    text-align: center;
  }

  &.main-price {
    transform: translateY(-20px);
  }
  &.main-price::before {
    content: "mais escolhido";
    text-transform: uppercase;
    font-size: 1.4rem;
    text-align: center;
    transform: translateY(-100%);
    width: 100%;
    left: 0;
    top: 0;
    padding: 0.6rem 0;
    background-color: #d97706;
    position: absolute;
    font-weight: bold;
    color: #fff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  &.yearly::before {
    content: "mais economia";
    text-transform: uppercase;
    font-size: 1.4rem;
    text-align: center;
    transform: translateY(-100%);
    width: 100%;
    left: 0;
    top: 0;
    padding: 0.6rem 0;
    //background-color: #d97706;
    border: 1px solid #d97706;
    position: absolute;
    font-weight: bold;
    color: #d97706;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .price {
    font-size: 3rem;
    font-weight: bold;
    color: #d97706;
    text-align: center;
    .decimals {
      font-size: 2rem !important;
    }
    margin-bottom: 1.2rem;
  }

  .divider {
    height: 1px;
    width: calc(100% + 6rem);
    margin-left: -3rem;
    background-color: #d97706;
    opacity: 0.5;
    margin-bottom: 2rem;
  }

  .feature-list {
    margin-bottom: 3.6rem;
    list-style: none;

    li {
      display: flex;
      gap: 0.4rem;
      align-items: center;

      .icon {
        color: #d97706;
      }
    }
  }

  .feature {
    font-size: 1.4rem;
  }
`;
export default function Plans({ title }) {
  const navigate = useNavigate();
  return (
    <Container className="flex direction-column">
      <div className="flex direction-column min-gap align">
        <h1 className="title">{title}</h1>
        <p className="plan-description">Você pode cancelar quando quiser!</p>
      </div>
      <div className="flex plans plans-wrapper">
        <PlanCard>
          <h1>plano mensal básico</h1>
          <p className="price">
            <span className="decimals">R$</span>19
            <span className="decimals">,99</span>
          </p>

          <ul className="feature-list">
            <li className="feature">
              <IonIcon className="icon" icon={checkmark} /> Até 5 encartes por
              dia
            </li>
          </ul>
          <Button
            $NoBgColor
            text={"Escolher"}
            $fullWidth
            action={() =>
              navigate(`/metodo-pagamento?lookup_key=express_basic_plan`)
            }
          />
        </PlanCard>
        <PlanCard className="main-price">
          <h1>plano mensal premium</h1>
          <p className="price">
            <span className="decimals">R$</span>34
            <span className="decimals">,99</span>
          </p>
          <ul className="feature-list flex min-gap direction-column">
            <li className="feature">
              <IonIcon className="icon" icon={checkmark} />
              Encartes ilimitados
            </li>
            <li className="feature">
              <IonIcon className="icon" icon={checkmark} /> Funcionalidades
              exclusivas
            </li>
            <li className="feature">
              <IonIcon className="icon" icon={checkmark} /> Modelos exclusivos
            </li>
          </ul>
          <Button
            text={"Escolher"}
            $fullWidth
            action={() =>
              navigate(`/metodo-pagamento?lookup_key=monthly_premium`)
            }
          />
        </PlanCard>
        <PlanCard className="yearly">
          <h1>plano trimestral</h1>
          <p className="price">
            <span className="decimals">R$</span>99
            <span className="decimals">,99</span>
          </p>
          <ul className="feature-list flex min-gap direction-column">
            <li className="feature">
              <IonIcon className="icon" icon={checkmark} />
              Encartes ilimitados
            </li>
            <li className="feature">
              <IonIcon className="icon" icon={checkmark} /> Funcionalidades
              exclusivas
            </li>
            <li className="feature">
              <IonIcon className="icon" icon={checkmark} /> Modelos exclusivos
            </li>
          </ul>
          <Button
            text={"Escolher"}
            $fullWidth
            $NoBgColor
            action={() =>
              navigate(`/metodo-pagamento?lookup_key=ofertas_trimester`)
            }
          />
        </PlanCard>
      </div>
    </Container>
  );
}
