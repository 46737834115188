import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "../sharable/Logo";
import { Modal, ModalHeader } from "../../SharableStyles/FormStyle";
import { IonIcon } from "@ionic/react";
import { code, mailOpenOutline, mailOutline } from "ionicons/icons";
import { Input } from "../sharable/InputStyle";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import Button from "../sharable/ActionButton";
import Loading from "../sharable/Loading";
import Error from "../sharable/Error";
const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;

  header {
    padding: 2rem 4.2rem;
    display: flex;
    justify-content: start;
    width: 100%;
  }
`;

const Main = styled.main`
  position: relative;
  padding: 2rem;
  gap: 3.2rem;
  .mail-icon {
    font-size: 10rem;
  }

  p {
    font-size: 1.2rem;
  }

  .wrapper {
    margin-top: 2rem;
  }

  .error-message {
    margin-top: 0.6rem;
    min-width: 100%;
  }
`;

export const requestValidationCodeNotification = async (token) => {
  const res = await fetch(`${BASE_URL}account-validation`, {
    headers: { Authorization: token },
  });
  return res;
};
export default function EmailValidation() {
  const [searchParams] = useSearchParams();
  const [validationCode, setValidationCodeCode] = useState();
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const [showValidationFailedMessage, setShowValidationFailedMessage] =
    useState(false);
  const navigate = useNavigate();
  const { token, user, setUser } = useOutletContext();

  const watchValidationCodeInput = useEffect(() => {
    if (validationCode && validationCode.length === 6)
      validateCode(validationCode);
  }, [validationCode]);

  const getCodeFromSearchParam = useEffect(() => {
    const perform = async () => {
      //if (user.accountVerified) navigate("/encartes");
      const code = searchParams.get("code");
      if (code) {
        setValidationCodeCode(code);
        validateCode(code);
      }
    };
    perform();
  }, []);

  const validateCode = async (code) => {
    setIsValidatingCode(true);
    const res = await fetch(`${BASE_URL}account-validation/${code}`, {
      headers: { Authorization: token },
      method: "POST",
    });
    if (res.ok) {
      const userCopy = { ...user };
      userCopy.accountVerified = true;
      setUser(userCopy);
      navigate("/encartes");
    } else if (res.status === 401) {
      setIsValidatingCode(false);
      setShowValidationFailedMessage(true);
    }
  };

  return (
    <Container>
      <header>
        <Logo />
      </header>
      <Modal className="modal">
        <ModalHeader>
          <h1>Verifique seu email!</h1>
          <p>Pode levar até 5 minutos para o código chegar no seu email</p>
        </ModalHeader>

        <Main className="flex">
          {isValidatingCode && <Loading txt="Validando código" size="1.6" />}
          <IonIcon icon={mailOpenOutline} className="mail-icon" />
          <div className="content">
            <>
              <h2>Enviamos o código no seu email</h2>
              <p>
                Digite o código de 6 dígitos que você recebeu no seu email na
                caixa abaixo
              </p>
              <div className="flex error-message">
                {showValidationFailedMessage && (
                  <Error message={"Código inválido"} />
                )}
              </div>
              <div className="wrapper flex min-gap">
                <Input
                  defaultValue={validationCode}
                  placeholder="000000"
                  onInput={(e) => setValidationCodeCode(e.target.value)}
                />

                <Button
                  text={"Verificar"}
                  action={() => {
                    if (validateCode) validateCode(validationCode);
                  }}
                />
              </div>
            </>
          </div>
        </Main>
      </Modal>
    </Container>
  );
}
