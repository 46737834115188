import { NAME_ABOVE_PRODUCT_LAYOUT } from "../components/WorkSpace/CanvasViewer/EncarteStyleConstants";

export default function ProductStyles(
  bgColor = "#F59E0B",
  txtColor = "black",
  priceBgColor = "#991B1B",
  priceTxtColor = "#F8FAFC",
  fontSize = "32",
  productLayout = NAME_ABOVE_PRODUCT_LAYOUT,
  gridColumn = undefined,
  gridRow = undefined
) {
  return {
    bgColor: bgColor,
    txtColor: txtColor,
    priceBgColor: priceBgColor,
    fontSize: fontSize,
    priceTxtColor: priceTxtColor,
    productLayout: productLayout,
    gridColumn: gridColumn,
    gridRow: gridRow,
  };
}
