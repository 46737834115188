import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { MenuDescription, MenuTitle } from "./Toolbar.jsx";
import EncarteCard from "../../sharable/EncarteCard.jsx";
import {
  arrowBack,
  arrowBackCircleSharp,
  arrowForward,
  arrowForwardCircleSharp,
} from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { useOutlet, useOutletContext } from "react-router";
import AdmZip from "adm-zip";
import { EncarteContext } from "../../Views/wo.jsx";
import { ENCARTE_MODELS } from "../../Views/Home.jsx";
import { loadEncarteModels } from "../../../services/ModelsService.js";
import Loading from "../../sharable/Loading.jsx";
import Model from "../../../OBJRep/Model.js";
import { Input } from "../../sharable/InputStyle.jsx";
import { searchIntoCollection } from "../../../services/Utils.js";
const Container = styled.div`
  height: calc(100vh - 8.4rem);
  overflow-y: auto;
  overflow-x: hidden;

  .model-name {
    width: 100%;
    margin-bottom: 3rem;
  }
`;
const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3.6rem;
`;

const Category = styled.div`
  padding: 0.6rem 0.8rem;
  font-size: 1.6rem;
  background-color: #0a0a0a;
  margin-right: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

const CategoryEncarteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const EncarteContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  position: relative;
  gap: 2rem;
  justify-content: center;
  position: relative;
  min-height: 5rem;
`;

const Arrow = styled(IonIcon)`
  display: none;
  position: absolute;
  top: 50%;
  font-size: 2.6rem;
  background-color: #fff;
  color: black;
  border-radius: 50px;
  padding: 0.4rem;
  box-shadow: 0px 0px 10px rgba(1, 1, 1, 0.3);
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: transparent;
    color: #fff;
    box-shadow: none;
  }
  &.left {
    transform: translate(-50%, -50%);
  }

  &.right {
    transform: translate(50%, -50%);
    right: 0;
  }
`;

const NoModelFoundSpan = styled.span`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  font-size: 1.4rem;
  text-align: center;
`;

const storage = window.localStorage;
//COMPONENT VERIFIED 31/05;
export default function ModelsMenuView({ updateCurrentModel, models }) {
  const [searchModels, setSearchModels] = useState([]);
  const [modelNameInput, setModelNameInput] = useState("");
  const [isSearchingModels, setIsSearchingModels] = useState();
  useEffect(() => {
    if (!modelNameInput || modelNameInput === "") return;
    setIsSearchingModels(true);
    const timeout = setTimeout(() => {
      setSearchModels(searchIntoCollection(modelNameInput, "name", models));
      setIsSearchingModels(false);
    }, 1500);
    return () => clearTimeout(timeout);
  }, [modelNameInput]);

  const handleModelClick = (urlTmp, url) => {
    updateCurrentModel(Model(urlTmp, url));
  };

  return (
    <Container>
      <MenuDescription>
        Em breve teremos mais modelos disponíveis!
      </MenuDescription>
      <MenuTitle>modelos</MenuTitle>
      <Input
        onInput={(e) => setModelNameInput(e.target.value)}
        className="model-name"
        placeholder="Nome do modelo que deseja buscar..."
      />
      <CategoriesContainer>
        <CategoryEncarteWrapper>
          <Category>Ofertões</Category>
          <EncarteContainer>
            <Arrow icon={arrowBack} className="left" />
            {!isSearchingModels &&
            searchModels.length <= 0 &&
            modelNameInput ? (
              <NoModelFoundSpan>
                Nenhum modelo encontrado ;( <br></br>
                <br></br> Você pode sugerir modelos que gostaria de ver aqui{" "}
                <br></br>
                Chama no whats ;)
              </NoModelFoundSpan>
            ) : isSearchingModels ? (
              <Loading
                txt={`Buscando modelo '${modelNameInput}'`}
                color={"#fff"}
                $NoBg
              />
            ) : modelNameInput && searchModels.length > 0 ? (
              searchModels.map((m) => (
                <EncarteCard
                  onClick={() => handleModelClick(m.urlTmp, m.url)}
                  url={m.urlTmp}
                  name={m.name}
                />
              ))
            ) : models ? (
              models.map((m) => (
                <EncarteCard
                  onClick={() => {
                    handleModelClick(m.urlTmp, m.url);
                  }}
                  url={m.urlTmp}
                  name={m.name}
                />
              ))
            ) : (
              <Loading color={"#fff"} $NoBg txt={"Carregando modelos..."} />
            )}
            <Arrow icon={arrowForward} className="right" />
          </EncarteContainer>
        </CategoryEncarteWrapper>
      </CategoriesContainer>
    </Container>
  );
}
