import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router";
import styled, { css } from "styled-components";
import { devices } from "../../Devices";
import { IonIcon } from "@ionic/react";
import { arrowBackOutline, returnUpBack } from "ionicons/icons";
export const LogoStyle = styled.div`
  text-transform: Capitalize;
  color: #d97706;
  font-size: 2.4rem;
  font-weight: bold;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
  font-style: normal;
  cursor: pointer;
  position: relative;
  display: block;

  .beta {
    position: absolute;
    bottom: -1.4rem;
    right: 0;
    font-size: 1.2rem;
    text-transform: Capitalize;
    color: black;
    font-weight: 500;
  }
  @media ${devices.tablet} {
    font-size: 1.6rem;

    .beta {
      font-size: 1rem;
      bottom: -1rem;
    }
  }
`;

export const LogoIconWrapper = styled.div`
  gap: 0.6rem;
  @media ${devices.tablet} {
    gap: 0.4rem;
  }
`;

const Icon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  @media ${devices.tablet} {
    width: 1.8rem;
    height: 1.8rem;
  }
  ${(url) =>
    url &&
    css`
      background-image: url(/images/crown.png);
      background-size: cover;
    `}
`;

const ReturnIconWrapper = styled.div`
  font-size: 2rem;
  margin-right: 3rem;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:hover {
    color: #d97706;
  }
  @media ${devices.tablet} {
    font-size: 1.4rem;
    margin-right: 1.8rem;
  }
`;
export default function Logo({ showGoBackButton, path, navigable }) {
  const context = useOutletContext();
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/encartes");
  };
  return (
    <LogoIconWrapper className="flex justify align">
      <div className="flex align ">
        {showGoBackButton && (
          <ReturnIconWrapper
            className="flex align"
            onClick={() => navigate(path)}
          >
            <IonIcon icon={arrowBackOutline} />
          </ReturnIconWrapper>
        )}
        <LogoStyle
          onClick={() => {
            if (navigable) navigate("/");
          }}
        >
          <div></div>
          OfertasExpress <p className="beta">criador de encartes</p>
        </LogoStyle>
      </div>

      {context && context.activeAccount && (
        <Icon className="icon" url={"/images/crown.png"} />
      )}
    </LogoIconWrapper>
  );
}
