import {
  BG_COLORS,
  TXT_COLORS,
} from "../components/WorkSpace/CanvasViewer/EncarteStyle";
import {
  ARIAL_FONT,
  FEED_INSTAGRAM,
  LOGO_SIZE_MEDIUM,
  NAME_ABOVE_PRODUCT_LAYOUT,
  ROBOTO_FONT,
  ROUND_LOGO_WITH_BORDER,
  STORIES,
} from "../components/WorkSpace/CanvasViewer/EncarteStyleConstants";

export default function Styles(
  bgColor = "#F59E0B",
  txtColor = "black",
  priceBgColor = "#991B1B",
  priceTxtColor = "#F8FAFC",
  fontFamily = ROBOTO_FONT,
  format = STORIES,
  fontSize = "32",
  productLayout = NAME_ABOVE_PRODUCT_LAYOUT,
  priceStyle = 1,
  logoStyle = ROUND_LOGO_WITH_BORDER,
  logoSize = LOGO_SIZE_MEDIUM
) {
  return {
    bgColor: bgColor,
    txtColor: txtColor,
    priceBgColor: priceBgColor,
    fontFamily: fontFamily,
    format: format,
    fontSize: fontSize,
    priceTxtColor: priceTxtColor,
    productLayout: productLayout,
    priceStyle: priceStyle,
    logoStyle: logoStyle,
    logoSize: logoSize,
  };
}
