import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { jwtDecode } from "jwt-decode";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";

import { Input } from "../sharable/InputStyle";
import {
  ModalHeader,
  Modal,
  Header,
  InputWrapper,
  TextLink,
} from "../../SharableStyles/FormStyle";
import Logo from "../sharable/Logo";
import Loading from "../sharable/Loading";
import OAuth from "../sharable/OAuth";
import Error from "../sharable/Error";
import Button from "../sharable/ActionButton";
import { emailRegex, passwordRegex, validateField } from "../../services/Utils";
import { setTokenCookie } from "../../TokenCookie";

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
const Container = styled.div`
  height: 100vh;
  width: 100vw;

  .input-link-wrapper {
    p {
      margin-top: 0.6rem;
      text-align: left;
    }
  }
`;

//COMPONENT VERIFIED 31/05;
export default function LoginView() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const [logging, setLogging] = useState();
  const [params, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    const p = params.get("refresh");
    const refresh = !p ? "" : p.toLocaleLowerCase();
    try {
      const tkn = Cookies.get("tkn");
      if (!tkn || tkn === "") return;
      const info = jwtDecode(tkn);
      if (info.exp * 1000 > new Date().valueOf() && refresh !== "true") {
        navigate("/encartes");
        return;
      } else {
        setErrorMessage("Sua sessão expirou. Entre novamente!");
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const validateFields = () => {
    const emailValidation = validateField(
      "email",
      email,
      true,
      false,
      0,
      0,
      emailRegex
    );
    const passwordValidation = validateField(
      "senha",
      password,
      true,
      true,
      6,
      8,
      ""
    );
    if (!emailValidation.isValid || !passwordValidation.isValid) {
      setErrorMessage(emailValidation.message + passwordValidation.message);
      return false;
    } else {
      return true;
    }
  };

  const logIn = async () => {
    const body = {
      email: email,
      password: password,
    };

    const res = await fetch(`${BASE_URL}auth/basic/login`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.ok) {
      setErrorMessage("Autenticado como " + email);
      const token = await res.text();
      setTokenCookie(token);
      navigate("/encartes");
    } else if (res.status === 404)
      setErrorMessage(
        "Não existe nenhuma conta vinculada a esse email. Tente criar uma nova conta"
      );
    else if (res.status === 401)
      setErrorMessage("Email ou Senha estão incorretos");
    else if (res.status === 403)
      setErrorMessage(
        "Método de autenticação incorreto, entre com sua conta do google."
      );
  };
  return (
    <Container>
      <Header>
        <Logo navigable={true} />
      </Header>
      <Modal>
        {logging && <Loading />}
        <ModalHeader>
          <h1>entrar</h1>
          <p>Bem vindo de volta ;).</p>
        </ModalHeader>
        <InputWrapper>
          {errorMessage && <Error message={errorMessage} />}
          <Input
            placeholder="Email"
            type="email"
            onInput={(e) => setEmail(e.target.value)}
          />
          <div className="input-link-wrapper direction-column flex">
            <Input
              placeholder="Senha"
              onInput={(e) => setPassword(e.target.value)}
              type="password"
            />
            <TextLink>
              <Link to="/recuperar-conta">Esqueci minha senha</Link>
            </TextLink>
          </div>
          <Button
            $fullWidth
            text={"Entrar"}
            action={() => {
              const isFieldsValid = validateFields();
              if (isFieldsValid) logIn();
            }}
          />
          <OAuth
            action={"login"}
            setLogging={setLogging}
            setErrorMessage={setErrorMessage}
            message={
              "Se você já possui uma conta, entre com a conta do google que você usou anteriormente!"
            }
          />
          <TextLink>
            Ainda não possui uma conta? <Link to="/registrar">Criar conta</Link>
            .
          </TextLink>
        </InputWrapper>
      </Modal>
    </Container>
  );
}
