//FORMATS
export const FEED_INSTAGRAM = "feed Instagram";
export const STORIES = "stories";
export const PANFLETO = "panfleto";
export const PAISAGEM = "paisagem";
//MAX PRODUCTS FOR EACH FORMAT
export const MAX_PRODUCTS_FEED_FORMAT = 8;
export const MAX_PRODUCTS_STORIES_FORMAT = 12;
export const MAX_PRODUCTS = 16;

//DIMENSIONS
export const ENCARTE_HEADER_PX_STORIES = 600;
export const ENCARTE_FOOTER_PX_STORIES = 220;

export const ENCARTE_HEADER_PX_FEED = 380;
export const ENCARTE_FOOTER_PX_FEED = 120;

export const FEED_INSTAGRAM_DIM = {
  width: 1080,
  height: 1080,
  header: 380,
  footer: 60,
};

export const STORIES_DIM = {
  width: 1080,
  height: 1920,
  header: 600,
  footer: 220,
};

export const PANFLETO_DIM = {
  width: 2480,
  height: 3508,
  header: 920,
  footer: 400,
};

export const PAISAGEM_DIM = {
  width: 1600,
  height: 1131,
  header: 350,
  footer: 100,
};

//FONTS
export const ARIAL_FONT = "arial";
export const TIMES_NEW_ROMAN_FONT = "times new roman";
export const ROBOTO_FONT = "roboto";
export const PROTEST_STRIKE_FONT = "Protest Strike";
export const UBUNTU_FONT = "Ubuntu";
export const INTER_FONT = "Inter";
export const BLINKER_FONT = "Blinker";
export const ALEO_FONT = "Aleo";
export const OSWALD_FONT = "Oswald";
export const POPPINS_FONT = "Poppins";
export const STAATLICHES_FONT = "Staatliches";
export const TEKO_FONT = "Teko";

//UNITY MEASUREMENT
export const UNITYS = ["unid", "kg", "caixa", "pct", "metro", "dz", "cartela"];

//PRICES

export const DEFAULT_PRICE_STYLE = "1";
export const ROUND_AND_SQUARE_PRICE_STYLE = "2";
export const ROUNDED_PRICE_STYLE = "3";
export const ROUGHLY_ROUNDED_PRICE_STYLE = "4";

//FONT SIZES
export const FONT_SIZES = [
  "18",
  "22",
  "26",
  "30",
  "34",
  "38",
  "40",
  "50",
  "60",
  "70",
  "80",
  "90",
  "100",
];

//LOGO SIZES

export const LOGO_SIZE_SMALL = 200;
export const LOGO_SIZE_MEDIUM = 350;
export const LOGO_SIZE_LARGE = 450;

//LOGO STYLE
export const ROUND_LOGO_WITH_BORDER = "ROUND_WITH_BORDER";
export const ROUND_LOGO_WITHOUT_BORDER = "ROUND_WITHOUT_BORDER";
export const SQUARE_LOGO_WITH_BORDER = "SQUARE_WITH_BORDER";
export const SQUARE_LOGO_WITHOUT_BORDER = "SQUARE_WITHOUT_BORDER";

//CANVAS
export const CANVAS_TARGET_CLASSNAME = "CANVAS";
export const CANVAS_WRAPPER_PRINT_TARGET_CLASSNAME = "CANVAS_WRAPPER";
export const CANVAS_VIWER_TARGET_CLASSNAME = "CANVAS_VIEWER";

//PRODUCT_LAYOUT
export const NAME_ABOVE_PRODUCT_LAYOUT = "NAME_ABOVE";
export const NAME_BENEATH_PRODUCT_LAYOUT = "NAME_BENEATH";
export const TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT = "TWO_COLUMNS_NAME_LEFT";
