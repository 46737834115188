import React from "react";
import styled from "styled-components";
import GoogleLoginButton from "./GoogleLoginButton";
import { GoogleOAuthProvider } from "@react-oauth/google";

const OAuthWrapper = styled.div`
  width: 100%;
  text-align: center;
  gap: 1rem;
  span {
    font-size: 1.4rem;
  }
  .message {
    margin-bottom: 1.4rem;
  }
`;

//COMPONENT VERIFIED 31/05;
export default function OAuth({
  setLogging,
  message,
  setErrorMessage,
  action,
}) {
  return (
    <OAuthWrapper className="flex direction-column">
      <span className="message">{message}</span>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <GoogleLoginButton
          action={action}
          setErrorMessage={setErrorMessage}
          setLogging={setLogging}
        />
      </GoogleOAuthProvider>
    </OAuthWrapper>
  );
}
