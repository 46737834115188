import React from "react";
import styled from "styled-components";
import Logo from "../sharable/Logo";
import { Link } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { sadOutline } from "ionicons/icons";
const Container = styled.div`
  height: 100vh;
  header {
    padding: 2rem 3rem;
    justify-content: start;
  }

  main {
    height: 100%;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 1.8rem;
    }
    p {
      text-align: center;
      font-size: 1.6rem;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 0.4rem;
      align-items: center;
    }
  }

  a:link,
  a:visited {
    all: unset;
    color: #d97706;
    border-bottom: 1px solid #d97706;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export default function ErrorView() {
  return (
    <Container className="flex direction-column">
      <header className="flex">
        <Logo />
      </header>
      <main className="flex min-gap direction-column">
        <p>
          Alguma coisa deu errado <IonIcon icon={sadOutline} className="icon" />
        </p>

        <p>
          {" "}
          Se você está vendo essa tela, significa que houve algum erro na
          aplicação. Nós informe no
          <a href="https://wa.me/message/2GPGFCP27PUCD1">Whatsapp</a> para que
          possamos resolver o problema.
        </p>
      </main>
    </Container>
  );
}
