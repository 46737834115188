import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { IonIcon } from "@ionic/react";
import { logoGoogle } from "ionicons/icons";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { setTokenCookie } from "../../TokenCookie";
const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
const Button = styled.button`
  all: unset;
  padding: 0.8rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  .icon {
    font-size: 1.4rem;
  }
  border-radius: 5px;
  font-size: 1.4rem;
  gap: 0.8rem;
  justify-content: center;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  font-weight: 550;
  color: #black;
  background-color: transparent;
  border: 1px solid black;
  &:hover {
    border-color: transparent;
  }

  position: relative;
`;

//COMPONENT VERIFIED
export default function GoogleLoginButton({
  setLogging,
  setErrorMessage,
  action,
}) {
  const navigate = useNavigate();
  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (access) => {
      try {
        const body = {
          code: access.code,
          scope: access.scope,
        };

        const url = `${BASE_URL}auth/google/${action}`;
        const res = await fetch(url, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });

        const bodyTxt = await res.text();
        if (res.ok) {
          setTokenCookie(bodyTxt);
          navigate("/encartes");
        } else if (res.status == 409) {
          setErrorMessage(
            "Esse email já está sendo utilizado. Tente fazer login."
          );
        } else if (res.status == 404) {
          setErrorMessage(
            "Não existe nenhuma conta vinculada a esse email. Tente criar uma nova conta"
          );
        } else if (res.status == 403) {
          setErrorMessage(
            "Método de autenticação incorreto, tente utilizar sua senha."
          );
        }
        setLogging(false);
      } catch (err) {
        navigate("/entrar");
      }
    },
    onError: (errorResponse) => {
      setLogging(false);
    },
    onNonOAuthError: (e) => {
      setLogging(false);
    },
  });

  return (
    <Button
      onClick={() => {
        setLogging(true);
        try {
          googleLogin();
        } catch (err) {}
      }}
    >
      <span className="icon">Entrar com Google</span>
      <IonIcon icon={logoGoogle} />
    </Button>
  );
}
