import React from "react";
import styled from "styled-components";
import Logo from "../sharable/Logo";
import { IonIcon } from "@ionic/react";
import { card } from "ionicons/icons";
import { devices } from "../../Devices";
import { useNavigate, useSearchParams } from "react-router-dom";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 2rem 3.6rem;
  header {
    justify-content: start;
  }

  h1 {
    text-align: center;
    margin-top: 4.2rem;
    font-size: 3rem;
    font-weight: 600;
  }
  main {
    height: 100%;
    width: 100%;
    gap: 5.6rem;
    justify-content: center;

    @media ${devices.tablet} {
      flex-direction: column;
      gap: 3.6rem;
    }
  }
`;

const Card = styled.div`
  border: 1px solid grey;
  padding: 1.6rem 3.2rem;
  border-radius: 10px;
  cursor: pointer;
  max-width: 350px;
  transition: all 300ms ease-in-out;

  @media ${devices.tablet} {
    &:first-child {
      margin-top: 3.6rem;
    }
  }

  &:hover {
    background-color: #d97706;
    color: #fff;
    border-color: transparent;
  }
  img,
  .img {
    height: 100px;
    width: 100px;
  }

  .text {
    span {
      font-size: 1.6rem;
      font-weight: bold;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

export default function PaymentMethods() {
  const [params, setSeachParams] = useSearchParams();
  const navigate = useNavigate();
  const handlePixPayment = () => {
    window.location =
      "https://wa.me/5577981324172?text=Ol%C3%A1.%20Quero%20pagar%20a%20plataforma%20no%20pix";
  };

  const handleCreditCardPayment = () => {
    navigate(`/assinatura?lookup_key=${params.get("lookup_key")}`);
  };
  return (
    <Container className="flex direction-column">
      <header className="flex">
        <Logo showGoBackButton={true} path={"/planos"} />
      </header>
      <h1>Escolha seu método de pagamento</h1>
      <main className="flex align justify-center">
        <Card className="flex min-gap align" onClick={handlePixPayment}>
          <div className="text flex direction-column min-gap">
            <span>Pagar com pix</span>
            <p>Manualmente enviar pix para renovação da assinatura</p>
          </div>
          <img src="images/pix.png" alt="" />
        </Card>
        <Card className="flex min-gap align" onClick={handleCreditCardPayment}>
          <div className="text flex direction-column min-gap">
            <span>Pagar com cartão de crédito</span>
            <p>Sua assinatura é renovada automaticamente</p>
          </div>
          <IonIcon className="img" icon={card} />
        </Card>
      </main>
    </Container>
  );
}
