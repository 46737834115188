import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const SwitchStyle = styled.div`
  width: 40px;
  height: 20px;
  border-radius: 15px;
  //background-color: #d97706;
  margin-bottom: 0.4rem;
  border: 1px solid grey;
  position: relative;
  cursor: pointer;
  &::before {
    content: "";
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: grey;
    border-radius: 50%;
    transition: all 200ms ease-in-out;
    ${({ on }) =>
      on &&
      css`
        background-color: #d97706;
        left: 35px;
        transform: translate(-100%, -50%);
      `}
  }
  ${({ on }) =>
    on &&
    css`
      border-color: #d97706;
    `}
`;

export default function Switch({ on, callback }) {
  const handleSwitchClick = () => {
    if (on) callback(false);
    else callback(true);
  };
  return <SwitchStyle on={on} onClick={handleSwitchClick}></SwitchStyle>;
}
