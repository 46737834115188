import React from "react";
import styled, { css, keyframes } from "styled-components";

const PopUpStyle = styled.div`
  width: 25rem;
  border-radius: 5px;
  background-color: #171717;
  color: #fff;
  line-height: 1.3;
  font-size: 1.2rem;
  z-index: 10;
  box-shadow: 0px 10px 15px rgba(1, 1, 1, 0.2);

  button {
    all: unset;
    margin-top: 1rem;
    cursor: pointer;
    padding: 0.6rem 1.2rem;
    border: #d9d9d9 1px solid;
    align-self: end;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
    text-transform: capitalize;
    &:hover {
      border-color: transparent;
      color: #d97706;
    }
  }

  .txt-button-wrapper {
    padding: 1.2rem;
  }

  ${({ position, top, right }) => css`
    position: ${position};
  `}
`;

const TimeOutAnimation = keyframes`
  to {
    width: 0%;
  }
`;

const PopUpTimeOut = styled.div`
  width: 100%;
  height: 5px;
  background-color: #d97706;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  animation: ${TimeOutAnimation} 15s linear;
`;
export default function PopUp({
  text,
  buttonText,
  showPopUp,
  setShowPopUp,
  position = "fixed",
  top = "10rem",
  right = "4.2rem",
}) {
  return (
    <>
      {showPopUp && (
        <PopUpStyle
          position={position}
          top={top}
          right={right}
          className="flex direction-column"
        >
          <div className="txt-button-wrapper flex direction-column">
            <p>{text}</p>
            <button onClick={() => setShowPopUp(false)}>{buttonText}</button>
          </div>
          <PopUpTimeOut />
        </PopUpStyle>
      )}
    </>
  );
}
