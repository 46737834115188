import ProductObj from "../../../OBJRep/Product";
import {
  NAME_ABOVE_PRODUCT_LAYOUT,
  TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
} from "../CanvasViewer/EncarteStyleConstants";

const FEED_GRID_3_PRODUCTS_1 = {
  products: products(
    3,
    [0, 1, 2],
    ["1/2", "2/3", "3/4"],
    ["1/2", "1/2", "1/2"],
    [
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
    ]
  ),
  grid: Grid(3, 1),
};
const FEED_GRID_3_PRODUCTS_2 = {
  products: products(
    3,
    [0, 1, 2],
    ["1/3"],
    ["1/2"],
    [
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ]
  ),
  grid: Grid(2, 2),
};
const FEED_GRID_4_PRODUCTS_1 = {
  products: products(4, [0, 3], ["1/2", "3/4"], ["1/3", "1/3"]),
  grid: Grid(3, 2),
};
const FEED_GRID_4_PRODUCTS_2 = {
  products: products(
    4,
    [0, 3],
    ["1/2", "2/4"],
    ["1/3", "1/2"],
    [NAME_ABOVE_PRODUCT_LAYOUT, TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT]
  ),
  grid: Grid(3, 2),
};

const FEED_GRID_5_PRODUCTS_1 = {
  products: products(
    5,
    [0, 2, 3],
    ["1/3", "3/5", "2/4"],
    ["1/2", "1/2", "2/3"],
    [
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ]
  ),
  grid: Grid(4, 2),
};
const FEED_GRID_5_PRODUCTS_2 = {
  products: products(
    5,
    [0, 4, 3],
    ["1/2", "4/5", "2/4"],
    ["1/3", "1/3", "2/3"],
    [
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
    ]
  ),
  grid: Grid(4, 2),
};
const FEED_GRID_6_PRODUCTS_1 = {
  grid: Grid(4, 2),
  products: products(
    6,
    [0, 5],
    ["1/3", "1/3"],
    ["1/2", "2/3"],
    [TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT, TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT]
  ),
};
const FEED_GRID_6_PRODUCTS_2 = {
  grid: Grid(4, 2),
  products: products(6, [0, 5], ["1/2", "4/5"], ["1/3", "1/3"], undefined),
};

const FEED_GRID_7_PRODUCTS_1 = {
  grid: Grid(3, 3),
  products: products(
    7,
    [0, 5],
    ["1/2", "3/4"],
    ["1/3", "1/3"],
    [NAME_ABOVE_PRODUCT_LAYOUT, NAME_ABOVE_PRODUCT_LAYOUT]
  ),
};

const FEED_GRID_7_PRODUCTS_2 = {
  grid: Grid(5, 2),
  products: products(
    7,
    [0, 2],
    ["1/4", "4/6"],
    ["1/2", "1/2"],
    [TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT, TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT]
  ),
};

const STORIES_GRID_5_PRODUCTS_1 = {
  grid: Grid(3, 3),
  products: products(
    5,
    [0, 1],
    ["1/4", "1/4"],
    ["1/2", "3/4"],
    [TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT, TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT]
  ),
};

const STORIES_GRID_5_PRODUCTS_2 = {
  grid: Grid(3, 3),
  products: products(
    5,
    [0, 1],
    ["1/3", "1/3"],
    ["1/3", "3/4"],
    [NAME_ABOVE_PRODUCT_LAYOUT, TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT]
  ),
};

const STORIES_GRID_6_PRODUCTS_1 = {
  grid: Grid(3, 3),
  products: products(
    6,
    [0, 1, 2],
    ["1/2", "2/3", "3/4"],
    ["1/3", "1/3", "1/3"],
    [
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
      NAME_ABOVE_PRODUCT_LAYOUT,
    ]
  ),
};

const STORIES_GRID_6_PRODUCTS_2 = {
  grid: Grid(4, 3),
  products: products(
    6,
    [0, 1],
    ["1/3", "3/5"],
    ["1/3", "1/3"],
    [NAME_ABOVE_PRODUCT_LAYOUT, NAME_ABOVE_PRODUCT_LAYOUT]
  ),
};

export const FEED_GRIDS = [
  {
    title: "Grade com 3 produtos",
    gridsArr: [FEED_GRID_3_PRODUCTS_1, FEED_GRID_3_PRODUCTS_2],
  },
  {
    title: "Grade com 4 produtos",
    gridsArr: [FEED_GRID_4_PRODUCTS_1, FEED_GRID_4_PRODUCTS_2],
  },
  {
    title: "Grade com 5 produtos",
    gridsArr: [FEED_GRID_5_PRODUCTS_1, FEED_GRID_5_PRODUCTS_2],
  },
  {
    title: "Grade com 6 produtos",
    gridsArr: [FEED_GRID_6_PRODUCTS_1, FEED_GRID_6_PRODUCTS_2],
  },
  {
    title: "Grade com 7 produtos",
    gridsArr: [FEED_GRID_7_PRODUCTS_1, FEED_GRID_7_PRODUCTS_2],
  },
];

export const STORIES_GRIDS = [
  {
    title: "Grade com 5 produtos",
    gridsArr: [STORIES_GRID_5_PRODUCTS_1, STORIES_GRID_5_PRODUCTS_2],
  },
  {
    title: "Grade com 6 produtos",
    gridsArr: [STORIES_GRID_6_PRODUCTS_1, STORIES_GRID_6_PRODUCTS_2],
  },
];

function Grid(columns, rows) {
  return {
    columns: columns,
    rows: rows,
  };
}
function products(quantity, arrPositions, arrColumns, arrRows, arrLayouts) {
  const products = [];
  for (let i = 0; i < quantity; i++) {
    const p = ProductObj(i);
    p.imageURL = "Imagem";
    products.push(p);
  }
  if (arrPositions) {
    for (let i = 0; i <= arrPositions.length - 1; i++) {
      if (arrColumns)
        products[arrPositions[i]].styles.gridColumn = arrColumns[i];
      if (arrRows) {
        products[arrPositions[i]].styles.gridRow = arrRows[i];
      }
      if (arrLayouts) {
        products[arrPositions[i]].styles.productLayout = arrLayouts[i];
      }
    }
  }
  return products;
}
