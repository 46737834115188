import ProductImage from "../OBJRep/ProductImage";
import { normalizeQuery, toDataURL } from "./Utils";
const storage = window.localStorage;
export const searchProductImages = async (query, token, startsAt) => {
  let images = [];
  const searchResult = await searchVpsProductImages(query, token);
  images = searchResult.images;
  if (!searchResult.isExactMatch) {
    const googleImages = await searchGoogleProductImages(
      query,
      token,
      startsAt
    );
    images = images.concat(googleImages);
  }
  return images;
};

const searchGoogleProductImages = async (query, token, startsAt) => {
  const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
  const cache = loadCachedImages(query);
  if (cache && cache.length >= startsAt) return cache;
  const q = query.replaceAll(" ", "+");
  const url = `${BASE_URL}custom-search/${q}?starts-at=${
    startsAt ? startsAt : 1
  }`;
  const res = await fetch(url, { headers: { Authorization: token } });
  const json = await res.json();
  const items = json.items;
  cacheImages(query, items);
  return items.map((item) => ProductImage(item.link, item.link));
};

const searchVpsProductImages = async (query, token) => {
  const BASE_URL = process.env.REACT_APP_RESOURCES_BASE_URL;
  const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
  const res = await fetch(`${BASE_URL}/files/${query}`, {
    headers: { Authorization: token },
  });
  let images = [];
  let isExactMatch = false;
  if (res.ok) {
    const json = await res.json();
    console.log(json);
    isExactMatch = json.isExactMatch;
    images = await Promise.all(
      json.resourceImages.map(async (i) => {
        const link = await toDataURL(APP_DOMAIN + i.location, token);
        return ProductImage(i.location, link);
      })
    );
  }
  return {
    images: images,
    isExactMatch: isExactMatch,
  };
};

const cacheImages = (q, images) => {
  q = normalizeQuery(q);
  let cache = [];
  let queryCached = false;
  const item = JSON.parse(storage.getItem("imagesCache"));
  if (item) cache = item;
  cache.forEach((e) => {
    if (e.q === q) {
      queryCached = true;
      e.images = images;
    }
  });
  if (queryCached) return;
  cache.push({
    q: q,
    images: images,
    exp: new Date(Date.now() + 259200000).getTime(),
  });
  try {
    storage.setItem("imagesCache", JSON.stringify(cache));
  } catch (e) {
    cache = [];
    cache.forEach((el) => {
      if (el.exp > new Date().getTime()) cache.push(el);
    });
    storage.setItem("imagesCache", JSON.stringify(cache));
  }
};

const loadCachedImages = (query) => {
  query = normalizeQuery(query);
  const cache = storage.getItem("imagesCache");
  const array = JSON.parse(cache);
  let images;
  if (array)
    array.forEach((el) => {
      if (el.q === query) {
        images = el.images;
        el.exp = new Date(Date.now() + 259200000).getTime();
      }
    });
  storage.setItem("imagesCache", JSON.stringify(array));
  if (images)
    return images.map((image) => ProductImage(image.link, image.link));
};
