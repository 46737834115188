import React, { useState } from "react";
import Logo from "../sharable/Logo";
import { ModalHeader } from "../../SharableStyles/FormStyle";
import styled from "styled-components";

import { useNavigate, useOutletContext } from "react-router";
import PopUp from "../sharable/PopUp";
import { ErrorMessage } from "./Login";
import Error from "../sharable/Error";
import { devices } from "../../Devices";
const Container = styled.div`
  height: 100vh;
  header {
    width: 100%;
    padding: 2rem 3rem;
  }
`;

const Form = styled.div`
  gap: 2rem;
  box-shadow: 0px 0px 30px rgba(1, 1, 1, 0.12);
  input {
    cursor: pointer;
  }
  h1 {
    font-weight: 600;
    &:first-letter {
      text-transform: capitalize;
    }
  }

  span {
    color: #d97706;
  }

  .radio-label-wrapper {
    display: flex;
    font-size: 1.6rem;
    gap: 0.8rem;
  }

  .message-label-wrapper {
    margin-top: 2rem;
    label {
      font-size: 1.6rem;
      margin-bottom: 0.8rem;
    }
  }
  .message-field {
    padding: 0.4rem;
    resize: none;
  }

  main {
    padding: 0 2.4rem 2.4rem 2.4rem;

    button {
      all: unset;
      margin-top: 3rem;
      font-size: 1.8rem;
      background-color: #d97706;
      color: #fff;
      font-weight: 600;
      padding: 1rem 2rem;
      border-radius: 5px;
      cursor: pointer;
      transition: all 300ms ease-in-out;
      &:hover {
        background-color: #ae5f05;
      }
    }
  }

  @media ${devices.tablet} {
    transform: scale(0.8);
  }
`;
export default function QuestionaryView() {
  const { token } = useOutletContext();
  const [knowServiceFrom, setknowServiceFrom] = useState();
  const [message, setMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showPopUp, setShowPopUp] = useState(false);
  const navigate = useNavigate();
  const handleSendButton = async () => {
    const body = JSON.stringify({
      knowServiceFrom: knowServiceFrom,
      message: message,
    });
    const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
    const url = `${BASE_URL}user-feedback`;
    if (!knowServiceFrom) {
      setErrorMessage("Por favor, selecione uma opção");
      return;
    }
    fetch(url, {
      method: "POST",
      headers: { Authorization: token, "Content-Type": "application/json" },
      body: body,
    }).then(async (res) => {
      console.log(res.ok);
      if (res.ok) navigate("/encartes");
      else
        setErrorMessage(
          "Tivemos um problema ao salvar seu feedback ;(. Recarregue a página e tente novamente"
        );
    });
    setShowPopUp(true);
  };
  return (
    <Container className="flex align direction-column">
      <header className="flex">
        <Logo showGoBackButton={true} path={"/encartes"} />
      </header>
      <PopUp
        showPopUp={showPopUp}
        setShowPopUp={setShowPopUp}
        text={"Obrigado pelo feedback ;)"}
        buttonText={"Por nada!"}
      />

      <Form className="flex direction-column">
        <ModalHeader>
          <h1>
            Como você soube do <span>OfertasExpress</span>?
          </h1>
        </ModalHeader>
        {errorMessage && <Error message={errorMessage} />}
        <main>
          <ul className="list flex direction-column min-gap">
            <InputComponent
              label={"Influenciador Instagram"}
              setknowServiceFrom={setknowServiceFrom}
              value={"INFLUENCIADOR"}
            />
            <InputComponent
              label={"Anúncios Google/Instagram"}
              setknowServiceFrom={setknowServiceFrom}
              value={"ANUNCIO"}
            />
            <InputComponent
              label={"Mentoria do Cirão"}
              setknowServiceFrom={setknowServiceFrom}
              value={"CIRAO"}
            />

            <div className="message-label-wrapper flex direction-column">
              <label htmlFor="msg">
                Quer nos deixar alguma mensagem? (opcional)
              </label>
              <textarea
                className="message-field"
                id="msg"
                name="opiniao"
                rows="4"
                cols="50"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></textarea>
            </div>
          </ul>
          <button className="submit-button" onClick={handleSendButton}>
            Enviar
          </button>
        </main>
      </Form>
    </Container>
  );
}

const InputComponent = ({ value, label, setknowServiceFrom }) => {
  return (
    <div className="radio-label-wrapper">
      <input
        id={value}
        type="radio"
        value={value}
        name="op"
        onClick={() => setknowServiceFrom(value)}
      />
      <label htmlFor="influenciador">{label}</label>
    </div>
  );
};
