import { IonIcon } from "@ionic/react";
import { cloudUploadOutline } from "ionicons/icons";
import React, { useRef } from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;
const Input = styled.input`
  all: unset;
  width: 100%;
  height: 100%;
  opacity: 0;

  border-radius: 50px;
`;

const Button = styled.div`
  width: 10rem;
  height: 10rem;

  cursor: pointer;
  border-radius: 5px;
  position: relative;
  ${({ logo }) => {
    return logo
      ? css`
          background-image: url(${logo});
          background-size: cover;
          border: 1px solid #d9d9d9;
          .icon {
            display: none;
          }
        `
      : "background-color: #d9d9d9;";
  }}
  .icon {
    font-size: 2.4rem;
    color: black;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
`;
const Span = styled.span`
  font-size: 1.4rem;
  text-align: center;
`;

export default function LogoInput({ updateMarcaInfo, marcaInfo }) {
  const button = useRef();

  const submitLogo = async (e) => {
    const fr = new FileReader();
    const file = fr.readAsDataURL(e.target.files[0]);
    fr.onloadend = (ev) => {
      //todo:limit file size;
      console.log(marcaInfo);
      marcaInfo.logoUrl = ev.target.result;
      updateMarcaInfo(marcaInfo);
    };
  };

  return (
    <Wrapper>
      <Button
        className="button"
        ref={button} /*</Wrapper>logo={marcaInfo.identity.logoUrl}*/
      >
        <IonIcon icon={cloudUploadOutline} className="icon" />
        <Input onChange={submitLogo} name="logo" type="file" />
      </Button>

      <Span>
        Clique para selecionar <br></br> sua logomarca
      </Span>
    </Wrapper>
  );
}
