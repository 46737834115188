import React from "react";
import styled from "styled-components";
import Button from "../components/sharable/ActionButton.jsx";
import { useNavigate } from "react-router";
import { devices } from "../Devices.js";

const padding = "4.2rem";

export const Header = styled.div`
  .logo {
    text-transform: uppercase;
    color: #d97706;
    font-size: 2.4rem;
    font-weight: bold;
  }
  display: flex;
  justify-content: start;
  padding: 2rem 4.2rem;
`;

export const Modal = styled.div`
  height: min-content;
  width: min-content;
  min-width: 50rem;
  border-radius: 5px;
  margin: 3.6rem auto;
  box-shadow: 0 10px 20px 10px rgba(1, 1, 1, 0.07);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  position: relative;
  @media ${devices.mobileL} {
    transform: scale(0.8);
  }
`;

export const ModalHeader = styled.div`
  padding: 2rem ${padding} 2rem ${padding};
  border-bottom: 1px solid rgba(1, 1, 1, 0.5);
  h1 {
    font-size: 2.4rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  p {
    font-size: 1.2rem;
    color: black;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.1rem 20% 4.2rem 20%;
  gap: 2.4rem;
`;

export const Main = styled.div`
  padding: 3rem 5.8rem 4.8rem 5.8rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Border = styled.div`
  border-bottom: 1px solid rgba(1, 1, 1, 0.5);
`;

const FormWrapper = styled.div`
  padding: 0rem ${padding} ${padding} ${padding};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 5.4rem;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 1.8rem;
    text-transform: capitalize;
    cursor: pointer;
    transition: color 300ms ease-in-out;
  }

  span:hover {
    color: #d97706;
  }
`;

export const Step = styled.span`
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  font-size: 1rem;
  text-transform: uppercase;
`;

export const TextLink = styled.p`
  font-size: 1.4rem;
  text-align: center;

  a:link,
  a:visited {
    color: #d97706;
    transition: opacity 300ms ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }
`;
