import React from "react";
import styled from "styled-components";
import Button from "./ActionButton";

const Container = styled.div`
  position: relative;

  input {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }
`;

export default function UploadButton({ $fullWidth, size, text, perform }) {
  const handleInputChange = (e) => {
    const fr = new FileReader();
    fr.onload = async (e) => {
      const buffer = e.target.result;
      const blob = new Blob([buffer]);
      const url = window.URL.createObjectURL(blob);
      perform(url);
    };
    fr.readAsArrayBuffer(e.target.files[0]);
  };
  return (
    <Container className="flex">
      <Button
        $fullWidth={$fullWidth}
        size={size}
        text={text}
        action={() => {}}
      />
      <input type="file" onChange={handleInputChange} />
    </Container>
  );
}
