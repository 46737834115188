import Model from "../OBJRep/Model";
import { authenticateUrl, toDataURL } from "./Utils";

const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
const RESOURCES_BASE_URL = process.env.REACT_APP_RESOURCES_BASE_URL;
export const loadEncarteModels = async (token) => {
  const res = await fetch(RESOURCES_BASE_URL + "/files/models", {
    headers: { Authorization: token },
  });

  if (res.status === 404) return [];
  const result = await res.json();

  const files = result.resourceImages;
  const links = await Promise.all(
    files.map((f) => {
      const url = APP_DOMAIN + f.location;
      return toDataURL(url, token);
    })
  );

  return files.map((f, i) => {
    const fileName = f.name.split(".")[0];
    return Model(links[i], f.location, fileName);
  });
};

export const loadEncarteModel = async (location, token) => {
  const url = await toDataURL(APP_DOMAIN + location, token);
  return Model(url, location);
};
