import React from "react";
import styled, { css, keyframes } from "styled-components";
import Logo from "../sharable/Logo";
import Button from "../sharable/ActionButton";
import { useNavigate } from "react-router";
import { devices } from "../../Devices";
import Plans from "../sharable/Plans";
const Container = styled.div`
  position: relative;
  margin-bottom: 10rem;
  scroll-behavior: smooth;
  gap: 5.8rem;
  @media ${devices.tablet} {
    nav {
      display: none;
    }
  }
  .presentation {
    border: 2px solid #0a0a0a;
    border-radius: 5px;
    height: auto;

    background-image: url(pc.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 60rem;
    height: 27rem;

    video {
      display: none;
    }
    @media ${devices.tablet} {
      border-color: transparent;
      width: 30rem;
      height: auto;
      background-image: none;
      video {
        border-radius: 5px;
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }

  .help {
    position: fixed;
    bottom: 5%;
    right: 5%;
    font-size: 1.2rem;
    text-transform: uppercase;
    cursor: pointer;
    color: black;
    gap: 0.8rem;
    span {
      transition: all 300ms ease-in-out;
      opacity: 1;
    }
    &:hover {
      span {
        opacity: 1;
        //  color: #d97706;
      }
    }
    img {
      display: inline-block;
      padding: 0.6rem;
      width: 5rem;
      height: 5rem;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 15px 15px rgba(1, 1, 1, 0.3);
    }
    z-index: 20;
  }

  .hero-text {
    width: 40%;
    h1 {
      text-transform: uppercase;
      font-size: 3.6rem;
      font-weight: 400;
      line-height: 1.3;
    }
    p {
      margin-top: 1rem;
      font-size: 1.4rem;

      span {
        color: #d97706;
      }
    }
    .cta {
      margin-top: 3.6rem;
    }
    @media ${devices.tablet} {
      width: 80%;
      text-align: center;
      h1 {
        font-size: 2.8rem;
        line-height: 1;
      }
      p {
        font-size: 1.2rem;
      }
    }
  }

  .header-hero-wrapper {
    //height: 100vh;
  }
`;

const Header = styled.div`
  padding: 1.2rem 4.2rem;

  nav {
    font-size: 1.6rem;
    a:link,
    a:visited {
      all: unset;
    }

    span {
      transition: color 200ms ease-in-out;
      cursor: pointer;
    }
    span:hover {
      color: #d97706;
    }
  }
`;

const HeroWrapper = styled.div`
  margin-top: 9.2rem;
  @media ${devices.tablet} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin-top: 4.2rem;
  }
`;

const ModelsSection = styled.section`
  gap: 3.6rem;
  align-items: center;
  width: 100vw;
  h1 {
    font-size: 3rem;
    text-align: center;
    text-transform: capitalize;
  }
  overflow: hidden;
`;
const SlideAnimation = (x) => keyframes`
  0% {
  
  }
      50% {
      transform: translateX(-${x});
      }

      100% {
        translateX(${x});
      }

`;

const ModelsContainer = styled.div`
  display: flex;
  gap: 5.6rem;
  transform: translateX(35rem);

  animation: ${SlideAnimation("35rem")} 60s linear infinite;

  @media ${devices.tablet} {
    transform: translateX(80rem);
    animation: ${SlideAnimation("80rem")} 45s linear infinite;
  }
`;
const Model = styled.div`
  width: 28rem;
  height: 50rem;
  ${({ url }) => {
    return css`
      background-image: url("${url}");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    `;
  }}
`;

//COMPONENT VERIFIED 31/05;
export default function LandingPageView() {
  const navigate = useNavigate();
  return (
    <Container className="flex direction-column">
      <div className="header-hero-wrapper">
        <Header className="flex space-between align">
          <Logo />
          <nav>
            <a href="#plan-section">
              <span>Ver Planos</span>
            </a>
          </nav>
          <div className="flex min-gap">
            <Button
              text={"criar conta"}
              $NoBgColor
              action={() => navigate("/registrar")}
            />

            <Button text={"entrar"} action={() => navigate("/entrar")} />
          </div>
        </Header>
        <HeroWrapper className="flex space-around align justify">
          <div className="hero-text">
            <div>
              <h1>Quer aumentar as suas vendas?</h1>
              <p>
                Você sabia que grande parte dos consumidores compram pelo
                visual? Crie agora as suas ofertas, divulgue nas mídias sociais
                e atraia mais clientes para o seu negócio utilizando o{" "}
                <span className="">OfertasExpress</span>.
              </p>
            </div>
            <div className="cta">
              <Button
                text={"Criar ofertas por apenas R$19,99/mês"}
                action={() => {
                  navigate("/entrar");
                }}
              />
            </div>
          </div>

          <div className="presentation" src={"presentation.gif"} alt="">
            <video autoPlay loop muted>
              <source src="presentation.mp4" type="video/mp4" />
            </video>
          </div>
        </HeroWrapper>{" "}
      </div>
      <a
        href="https://wa.me/message/2GPGFCP27PUCD1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="help flex direction-column min-gap align justify">
          <img src="whatsapp.png" alt="" />
          <span>Whatsapp</span>
        </div>
      </a>
      <ModelsSection className="flex direction-column">
        <h1>Modelos</h1>
        <ModelsContainer>
          <Model url="images/examples/1.jpeg" />
          <Model url="images/examples/2.jpeg" />
          <Model url="images/examples/3.jpeg" />
          <Model url="images/examples/5.jpeg" />
          <Model url="images/examples/4.jpeg" />
          <Model url="images/examples/6.jpeg" />
        </ModelsContainer>
      </ModelsSection>
      <div id="plan-section">
        <Plans title={"Planos"} />
      </div>
    </Container>
  );
}
