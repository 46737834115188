import React from "react";
import styled from "styled-components";

const Message = styled.div`
  font-size: 1.4rem;
  justify-content: center;
  p {
    padding: 1.2rem 2.4rem;
    border: 1px solid #b91c1c;
    background-color: #fee2e2;
    text-align: center;
  }

  p:first-letter {
    text-transform: capitalize;
  }
`;
export default function Error({ message }) {
  return (
    <Message className="flex justify-center">
      <p>{message}</p>
    </Message>
  );
}
