import { IonIcon } from "@ionic/react";
import { syncOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { devices } from "../../Devices";
import Loading from "./Loading";

const ButtonStyle = styled.button`
  all: unset;
  background-color: #d97706;
  color: #fff;
  text-transform: uppercase;
  padding: 0.8rem 2.4rem;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  &&:hover {
    background-color: #ae5f05;
    transition: background-color 300ms ease-in-out;
  }

  ${({ doingAction }) =>
    doingAction
      ? css`
          background-color: rgba(1, 1, 1, 0.5);
          cursor: auto;
          &:hover {
            background-color: rgba(1, 1, 1, 0.5) !important;
          }
        `
      : `
  `}
  ${({ size }) =>
    size
      ? css`
          font-size: ${size}rem;
        `
      : `font-size: 1.6rem;
  `}
  ${({ $fullWidth }) => {
    return $fullWidth
      ? css`
          width: 100%;
          padding: 0.8rem 0;
          text-align: center;
        `
      : "";
  }}

   ${({ $NoBgColor }) => {
    return $NoBgColor
      ? css`
          background-color: transparent !important;
          color: #d97706 !important;
          border: 1px solid #d97706 !important;
          transition: all 500ms ease-in-out;
          &:hover {
            background-color: transparent !important;
            border-color: transparent !important;
            color: black !important;
          }
        `
      : "";
  }}
${({ color }) =>
    color &&
    css`
      &:hover {
        color: ${color} !important;
      }
    `}
    ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  @media ${devices.mobileL} {
    font-size: 1.4rem;
    line-height: 1;
    text-align: center;
  }
`;

export default function Button({
  $fullWidth,
  text,
  action,
  size,
  $NoBgColor,
  color,
  width,
}) {
  const [doingAction, setDoingAction] = useState();

  return (
    <ButtonStyle
      className="border-radius action-button"
      doingAction={doingAction}
      $fullWidth={$fullWidth}
      width={width}
      size={size}
      $NoBgColor={$NoBgColor}
      onClick={async () => {
        setDoingAction(true);
        await action();

        setDoingAction(false);
      }}
      color={color}
    >
      {doingAction && <Loading color={"black"} />}
      {text}
    </ButtonStyle>
  );
}
