import React from "react";
import { ModalHeader } from "../../SharableStyles/FormStyle";
import styled, { keyframes } from "styled-components";
import Button from "./ActionButton";
import { devices } from "../../Devices";

export const AppearAnimation = keyframes`
0% {
  opacity: 0%;
}

100% {
  opacity: 100%;
}
`;

const PopUp = styled.div`
  h1:first-letter {
    text-transform: uppercase;
  }
  z-index: 10;
  line-height: 1.2;
  max-width: 50rem;
  p {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.4rem;
  }
  border: none !important;
  position: absolute;
  right: 50%;
  top: 20rem;
  transform: translateX(50%);
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(1, 1, 1, 0.12);
  .assinar-button {
    all: unset;
    text-transform: uppercase;
    font-size: 1.4rem;
    padding: 0.6rem 1.2rem;
    border-radius: 2px;
    display: inline-block;
    transition: all 300ms ease-in-out;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      border-color: transparent;
      background-color: #d97706;
      color: #fff;
    }
  }
  animation: ${AppearAnimation} 500ms linear;
  @media ${devices.tablet} {
    width: 80%;
    h1 {
      font-size: 1.8rem;
    }
  }
`;

const Header = styled(ModalHeader)`
  border: none;
`;

export const ModalPopUp = ({
  title,
  showActionButton,
  text,
  buttonAction,
  buttonText,
}) => {
  return (
    <PopUp>
      <Header>
        <h1>{title}</h1>
        <p>{text}</p>
        {showActionButton && (
          <Button size="1.4" text={buttonText} action={buttonAction} />
        )}
      </Header>
    </PopUp>
  );
};
