import React from "react";
import styled, { css } from "styled-components";
import { MenuDescription, MenuTitle } from "./Toolbar";
import { useOutletContext } from "react-router";
import PremiumContentProtectiveLayer from "../../sharable/PremiumContentProtectiveLayer";
import Price from "../../sharable/Price";
import Styles from "../../../OBJRep/Styles";
import {
  DEFAULT_PRICE_STYLE,
  ROUGHLY_ROUNDED_PRICE_STYLE,
  ROUND_AND_SQUARE_PRICE_STYLE,
  ROUNDED_PRICE_STYLE,
} from "../CanvasViewer/EncarteStyleConstants";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 8.4rem);
  overflow-y: auto;

  ${({ isAccountPremium }) =>
    !isAccountPremium &&
    css`
      main {
        filter: blur(3px);
      }
    `}
`;

const PricesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  height: 150px;
  justify-content: center;
  cursor: pointer;
  &:hover {
    border-color: #d97706;
  }
  transition: all 300ms ease-in-out;
  ${({ selected }) => {
    if (selected)
      return css`
        background-color: #d97706;
      `;
  }}
`;

const styles1 = Styles();
const styles2 = Styles();
const styles3 = Styles();
styles1.priceStyle = DEFAULT_PRICE_STYLE;
styles2.priceStyle = ROUNDED_PRICE_STYLE;
styles3.priceStyle = ROUGHLY_ROUNDED_PRICE_STYLE;
export default function PricesMenuView({ props }) {
  const { activeAccount, isAccountPremium } = useOutletContext();

  const handlePriceChange = (e) => {
    const element = e.target.closest(".price-style");
    if (!element) return;

    const style = element.getAttribute("data-price-style");
    const stylesCopy = { ...props.encarteStyles };
    stylesCopy.priceStyle = style;
    console.log(style);
    props.updateEncarteStyles(stylesCopy);
  };

  return (
    <>
      <Container isAccountPremium={isAccountPremium}>
        {!isAccountPremium && <PremiumContentProtectiveLayer />}
        <main>
          <MenuDescription>
            Escolha o modelo de preço que melhor preferir
          </MenuDescription>
          <MenuTitle>Modelo de Preço</MenuTitle>
          <PricesWrapper onClick={handlePriceChange}>
            <Wrapper
              className="price-style"
              data-price-style={DEFAULT_PRICE_STYLE}
              selected={
                props.encarteStyles.priceStyle === DEFAULT_PRICE_STYLE
                  ? true
                  : false
              }
            >
              <Price
                styles={styles1}
                scale={0.8}
                unity={"cada"}
                price={"19,99"}
                position="relative"
              />
            </Wrapper>
            <Wrapper
              className="price-style"
              data-price-style={ROUND_AND_SQUARE_PRICE_STYLE}
              selected={
                props.encarteStyles.priceStyle === ROUND_AND_SQUARE_PRICE_STYLE
                  ? true
                  : false
              }
            >
              <Price
                styles={styles2}
                scale={0.8}
                unity={"cada"}
                price={"19,99"}
                position="relative"
              />
            </Wrapper>

            <Wrapper
              className="price-style"
              data-price-style={ROUGHLY_ROUNDED_PRICE_STYLE}
              selected={
                props.encarteStyles.priceStyle === ROUGHLY_ROUNDED_PRICE_STYLE
                  ? true
                  : false
              }
            >
              <Price
                styles={styles3}
                scale={0.8}
                unity={"cada"}
                price={"19,99"}
                position="relative"
              />
            </Wrapper>
          </PricesWrapper>
        </main>
      </Container>
    </>
  );
}
