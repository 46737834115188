import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { MenuDescription, MenuTitle } from "./Toolbar";
import { useOutletContext } from "react-router";
import PremiumContentProtectiveLayer from "../../sharable/PremiumContentProtectiveLayer";
import ProductLayout from "./ProductLayout";
import Switch from "../../sharable/Switch";
import ProductObj from "../../../OBJRep/Product";
import FeedCanvas from "../CanvasViewer/Canvas/FeedCanvas";
import {
  FEED_INSTAGRAM,
  NAME_ABOVE_PRODUCT_LAYOUT,
  STORIES,
  TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
} from "../CanvasViewer/EncarteStyleConstants";
import { grid } from "ionicons/icons";
import {
  FEED_GRID_3_PRODUCTS_1,
  FEED_GRID_3_PRODUCTS_2,
  FEED_GRID_4_PRODUCTS_1,
  FEED_GRID_4_PRODUCTS_2,
  FEED_GRID_5_PRODUCTS_1,
  FEED_GRID_5_PRODUCTS_2,
  FEED_GRID_6_PRODUCTS_1,
  FEED_GRID_6_PRODUCTS_2,
  FEED_GRID_7_PRODUCTS_1,
  FEED_GRID_7_PRODUCTS_2,
  FEED_GRIDS,
  STORIES_GRID,
  STORIES_GRID_5_PRODUCTS_1,
  STORIES_GRID_5_PRODUCTS_2,
  STORIES_GRID_6_PRODUCTS_1,
  STORIES_GRID_6_PRODUCTS_2,
  STORIES_GRIDS,
} from "./Grids";
import StoriesCanvas from "../CanvasViewer/Canvas/StoriesCanvas";
import Loading from "../../sharable/Loading";

const Container = styled.div`
  width: 100%;
  position: relative;
  height: calc(100vh - 8.4rem);
  overflow-y: auto;
  overflow-x: hidden;
  ${({ isAccountPremium }) =>
    !isAccountPremium &&
    css`
      main {
        filter: blur(3px);
      }
    `}
`;
const GridListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  ${({ automaticGrid }) => {
    return (
      automaticGrid &&
      css`
        //background-color: red;
      `
    );
  }}
`;
const GridSlide = styled.div`
  width: 35rem;
  display: flex;
  gap: 2rem;
  overflow: scroll;
`;
const GridWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
`;
const GridList = styled.div`
  overflow-x: scroll;
  h1 {
    font-size: 2rem;
  }
`;

const scale = 0.2;
export default function GridMenuView({ props }) {
  const { activeAccount, isAccountPremium } = useOutletContext();
  const [isGridGenerationAutomatic, setIsGridGenerationAutomatic] = useState(
    props.grid ? false : true
  );

  useEffect(() => {
    if (isGridGenerationAutomatic) props.updateGrid(undefined);
  }, [isGridGenerationAutomatic]);

  useEffect(() => {
    if (!props.encarteProducts || props.encarteProducts.length === 0)
      setIsGridGenerationAutomatic(true);
  }, [props.encarteProducts]);

  const handleGridChange = (grid) => {
    props.updateGrid(grid.grid);
    if (props.encarteProducts && props.encarteProducts.length > 0) {
    }
    props.updateEncarteProducts(grid.products);
  };
  return (
    <Container isAccountPremium={isAccountPremium}>
      {!isAccountPremium && <PremiumContentProtectiveLayer />}
      <main>
        <MenuDescription>
          Em breve você poderá customizar a grade de produtos
        </MenuDescription>
        <div>
          <span>Grid automático</span>
          <Switch
            callback={setIsGridGenerationAutomatic}
            on={isGridGenerationAutomatic}
          />
        </div>
        {!isGridGenerationAutomatic && (
          <>
            {" "}
            <MenuTitle>Grade de Produtos</MenuTitle>
            {props.encarteStyles.format === FEED_INSTAGRAM && (
              <GridListWrapper automaticGrid={isGridGenerationAutomatic}>
                {FEED_GRIDS.map((gridList) => (
                  <GridList>
                    <h1>{gridList.title}</h1>
                    <GridSlide>
                      {gridList.gridsArr.map((grid) => (
                        <GridFeedCanvas
                          props={props}
                          grid={grid}
                          handleGridChange={handleGridChange}
                          encarteProducts={props.encarteProducts}
                        />
                      ))}
                    </GridSlide>
                  </GridList>
                ))}
              </GridListWrapper>
            )}
            {props.encarteStyles.format === STORIES && (
              <GridListWrapper automaticGrid={isGridGenerationAutomatic}>
                {STORIES_GRIDS.map((gridList) => (
                  <GridList>
                    <h1>{gridList.title}</h1>
                    <GridSlide>
                      {gridList.gridsArr.map((grid) => (
                        <GridStoriesCanvas
                          props={props}
                          grid={grid}
                          handleGridChange={handleGridChange}
                          encarteProducts={props.encarteProducts}
                        />
                      ))}
                    </GridSlide>
                  </GridList>
                ))}
              </GridListWrapper>
            )}
          </>
        )}
      </main>
    </Container>
  );
}

function GridFeedCanvas({ props, grid, handleGridChange, encarteProducts }) {
  const [canvasProducts, setCanvasProducts] = useState(undefined);
  props.encarteStyles.fontSize = 26;
  useEffect(() => {
    if (!encarteProducts || encarteProducts.length === 0) {
      setCanvasProducts(() => grid.products);
      console.log("on if no products");
      return;
    }
    const pArr = updateGridWithExistingProducts(grid.products, encarteProducts);
    setCanvasProducts(pArr);
  }, [encarteProducts]);

  return (
    <>
      {" "}
      {canvasProducts ? (
        <GridWrapper
          onClick={() =>
            handleGridChange({ grid: grid.grid, products: canvasProducts })
          }
        >
          <FeedCanvas
            brand={props.brand}
            encarte={props.encarte}
            model={props.model}
            products={canvasProducts}
            scale={scale}
            styles={props.encarteStyles}
            grid={grid.grid}
          />
        </GridWrapper>
      ) : (
        <Loading $NoBg txt="Carregando grade de produtos..." />
      )}
    </>
  );
}

function GridStoriesCanvas({ props, grid, handleGridChange, encarteProducts }) {
  const [canvasProducts, setCanvasProducts] = useState(grid.products);
  props.encarteStyles.fontSize = 32;
  useEffect(() => {
    if (!encarteProducts || encarteProducts.length === 0) {
      setCanvasProducts(grid.products);
      return;
    }
    const pArr = updateGridWithExistingProducts(grid.products, encarteProducts);
    setCanvasProducts(pArr);
  }, [encarteProducts]);
  return (
    <GridWrapper
      onClick={() =>
        handleGridChange({ grid: grid.grid, products: canvasProducts })
      }
    >
      <StoriesCanvas
        brand={props.brand}
        encarte={props.encarte}
        model={props.model}
        products={canvasProducts}
        scale={scale}
        styles={props.encarteStyles}
        grid={grid.grid}
      />
    </GridWrapper>
  );
}

const updateGridWithExistingProducts = (gridProducts, encarteProducts) => {
  const pArr = gridProducts;
  const maxI =
    gridProducts.length <= encarteProducts.length
      ? gridProducts.length - 1
      : encarteProducts.length - 1;
  for (let i = 0; i <= maxI; i++) {
    gridProducts[i].name = encarteProducts[i].name;
    gridProducts[i].imageURLTmp = encarteProducts[i].imageURLTmp;
    gridProducts[i].imageURL = encarteProducts[i].imageURL;
    gridProducts[i].price = encarteProducts[i].price;
    gridProducts[i].unityMeasurement = encarteProducts[i].unityMeasurement;
  }
  return pArr;
};
/**    <GridList>
                  <h1>Grade com 3 produtos</h1>
                  <GridSlide>
                    <GridFeedCanvas
                      props={props}
                      grid={FEED_GRID_3_PRODUCTS_1}
                      handleGridChange={handleGridChange}
                      encarteProducts={props.encarteProducts}
                    />
                    <GridFeedCanvas
                      props={props}
                      grid={FEED_GRID_3_PRODUCTS_2}
                      handleGridChange={handleGridChange}
                      encarteProducts={props.encarteProducts}
                    />
                  </GridSlide>
                </GridList>
                <GridList>
                  <h1>Grade com 4 Produtos</h1>

                  <GridSlide>
                    <GridFeedCanvas
                      props={props}
                      grid={FEED_GRID_4_PRODUCTS_1}
                      handleGridChange={handleGridChange}
                      encarteProducts={props.encarteProducts}
                    />
                    <GridFeedCanvas
                      props={props}
                      grid={FEED_GRID_4_PRODUCTS_2}
                      handleGridChange={handleGridChange}
                      encarteProducts={props.encarteProducts}
                    />
                  </GridSlide>
                </GridList>
                <GridList>
                  <h1>Grade com 5 produtos</h1>
                  <GridSlide>
                    <GridFeedCanvas
                      props={props}
                      grid={FEED_GRID_5_PRODUCTS_1}
                      handleGridChange={handleGridChange}
                      encarteProducts={props.encarteProducts}
                    />
                    <GridFeedCanvas
                      props={props}
                      grid={FEED_GRID_5_PRODUCTS_2}
                      handleGridChange={handleGridChange}
                      encarteProducts={props.encarteProducts}
                    />
                  </GridSlide>
                </GridList>
                <GridList>
                  <h1>Grade com 6 produtos</h1>
                  <GridSlide>
                    <GridFeedCanvas
                      props={props}
                      grid={FEED_GRID_6_PRODUCTS_1}
                      handleGridChange={handleGridChange}
                      encarteProducts={props.encarteProducts}
                    />
                    <GridFeedCanvas
                      props={props}
                      grid={FEED_GRID_6_PRODUCTS_2}
                      handleGridChange={handleGridChange}
                      encarteProducts={props.encarteProducts}
                    />
                  </GridSlide>
                </GridList>
                <GridList>
                  <h1>Grade com 7 produtos</h1>
                  <GridSlide>
                    <GridFeedCanvas
                      props={props}
                      grid={FEED_GRID_7_PRODUCTS_1}
                      handleGridChange={handleGridChange}
                      encarteProducts={props.encarteProducts}
                    />
                    <GridFeedCanvas
                      props={props}
                      grid={FEED_GRID_7_PRODUCTS_2}
                      handleGridChange={handleGridChange}
                      encarteProducts={props.encarteProducts}
                    />
                  </GridSlide>
                </GridList> */
