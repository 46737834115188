import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "../sharable/Logo";
import { useNavigate, useOutlet, useOutletContext } from "react-router";
import Loading from "../sharable/Loading";
const Container = styled.div``;
const Header = styled.header`
  padding: 2rem 4.2rem;
`;
const Main = styled.main`
  h1 {
    text-transform: uppercase;
    text-align: center;
    margin: 3rem 0;
  }
`;

const Table = styled.div`
  margin: auto auto;
`;

const TableRow = styled.div`
  grid-template-columns: repeat(5, 250px);
  display: grid;
  span {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    width: 100%;
    display: inline-block;
    font-size: 1.4rem;
    padding: 0.6rem;
    &:last-child {
      border-right: none;
    }
  }
`;
export default function CustomersView() {
  const { token, user } = useOutletContext();
  const [customers, setCustomers] = useState([]);
  const [feedBacks, setFeedbacks] = useState();

  const navigate = useNavigate();
  const loadcustomers = useEffect(() => {
    const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
    async function perform() {
      if (user.authority !== "ADMIN") {
        navigate("/encartes");
        return;
      }
      const url = `${BASE_URL}user-collection`;
      const res = await fetch(url, {
        headers: { Authorization: token },
      });
      const customers = await res.json();
      setCustomers(customers);
    }
    perform();
  }, []);

  return (
    <Container>
      <Header className="flex">
        <Logo showGoBackButton={true} path={"/encartes"} />
      </Header>
      <Main className="flex justify-center direction-column">
        <h1>meus clientes</h1>
        {customers ? (
          <>
            <Table className="flex direction-column">
              <TableRow>
                <span>Nome</span>
                <span>Email</span>
                <span>Whatsapp</span>
                <span>Qntd. encartes</span>
                <span>Origem</span>
              </TableRow>
              {customers &&
                customers.map((c) => {
                  return (
                    <TableRow className="">
                      <span>{c.name}</span>
                      <span>{c.email}</span>
                      <span>{c.brand.social.whatsapp}</span>
                      <span>{c.encarteCollection.length}</span>
                      <span>{c.knowServiceFrom}</span>
                    </TableRow>
                  );
                })}
            </Table>
            <h1>
              Possível faturamento : R$
              {customers.length * 19.99} ({customers.length + " clientes"})
            </h1>
          </>
        ) : (
          <Loading />
        )}
      </Main>
    </Container>
  );
}

const checkAccountStatus = () => {};
