import React, { useContext } from "react";
import styled, { css } from "styled-components";
import {
  ROUGHLY_ROUNDED_PRICE_STYLE,
  ROUND_AND_SQUARE_PRICE_STYLE,
  ROUNDED_PRICE_STYLE,
} from "../WorkSpace/CanvasViewer/EncarteStyleConstants";

const Container = styled.div`
  height: 100%;
  justify-content: center;
`;

const Measurement = styled.div`
  text-transform: capitalize;
  position: relative;
  margin-left: 10%;
  font-weight: 600;
  margin-top: 5%;
  ${({ color, fontSize, scale }) => {
    const fontScale = fontSize * scale;
    return css`
      font-size: ${fontScale * 2 * 0.3}px !important;
    `;
  }}
`;

const GeneralStyle = css`
  bottom: 10%;
  right: 25%;
  transform: translate(25%, 25%);
  line-height: 1;
  widht: 100%;
  .number-wrapper {
    font-weight: 800;
    display: flex;
    align-items: center;
  }

  .price-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .sign {
    position: absolute;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
  }

  .decimal-unity-wrapper {
    display: flex;
    flex-direction: column;
    line-height: 1;
  }

  .decimal {
    letter-spacing: -1px;
  }

  ${({ styles, scale, position }) => {
    const fontScale = styles.fontSize * scale;
    const signBackgroundColor = styles.priceBgColor;
    const signTextColor = styles.priceTxtColor;
    const pricePosition = position ? position : "absolute";
    return css`
      font-size: ${fontScale * 2.3}px;
      color: ${styles.txtColor} !important;
      position: ${pricePosition};
      .price-wrapper {
        background-color: ${styles.priceBgColor};
        color: ${styles.priceTxtColor};
      }
      .decimal {
        font-size: ${fontScale * 2 * 0.5}px;
      }

      .sign {
        font-size: ${fontScale * 2 * 0.36}px;
        background-color: ${signBackgroundColor};
        color: ${signTextColor};
      }
    `;
  }}
`;

const DefaultPrice = styled.div`
  ${GeneralStyle}
  bottom: 10%;
  right: 25%;
  transform: translate(25%, 25%);
  line-height: 1;
  justify-content: end;
  height: min-content;

  integer {
    height: 100%;
  }
  .decimal {
    grid-column: 3/4;
    grid-row: 1/2;
    vertical-align: top;
    display: inline-block;
    font-weight: 800;
  }
  .number-wrapper {
    padding: 2% 3% 2% 5%;
  }

  .decimal-unity-wrapper,
  .sign {
    left: 0;
  }

  .decimal-unity-wrapper {
    display: inline-block;
    line-height: 0.4;
  }

  .sign {
    border-radius: 50%;
    transform: translateX(-50%);
  }

  ${({ styles, scale }) => {
    const fontScale = styles.fontSize * scale;
    const signwidthHeight = `${fontScale * 1.7}px`;
    const topBottomPadding = `${fontScale / 4}px`;
    const rightPadding = `${fontScale / 2}px`;
    const leftPadding = `${fontScale * 1}px`;
    const signBgColor = `${
      styles.priceBgColor ? styles.priceTxtColor : "inherit"
    }`;
    const signTxtColor = `${
      styles.priceBgColor ? styles.priceBgColor : styles.priceTxtColor
    }`;
    const borderRadius = `${fontScale}px`;
    return css`
      .price-wrapper {
        border-radius: ${5 * scale}px;
        padding: ${topBottomPadding} ${rightPadding} ${topBottomPadding}
          ${leftPadding};
        border-top-left-radius: ${borderRadius};
        border-bottom-left-radius: ${borderRadius};
      }

      .sign {
        height: ${signwidthHeight};
        width: ${signwidthHeight};
        background-color: ${signBgColor};
        color: ${signTxtColor};
      }
    `;
  }}
`;

const SignColors = css`
  ${({ styles, scale }) => {
    const fontScale = styles.fontSize * scale;
    const signwidthHeight = `${fontScale * 1.7}px`;
    const signBgColor = `${
      styles.priceBgColor ? styles.priceTxtColor : "inherit"
    }`;
    const signTxtColor = `${
      styles.priceBgColor ? styles.priceBgColor : styles.priceTxtColor
    }`;
    return `     .sign {
        background-color: ${signBgColor};
        color: ${signTxtColor};
        width: ${signwidthHeight};
        height: ${signwidthHeight};
      }`;
  }}
`;

const RoundedPrice = styled.div`
  ${GeneralStyle}
  display: flex;

  .sign {
    top: 5%;
    left: 0;
    transform: translate(-50%, 0);
  }

  .number-wrapper {
    font-weight: 800;
    z-index: 10;
  }

  .price-wrapper {
    border-radius: 50%;
  }
  ${({ styles, scale }) => {
    const fontSize = styles.fontSize * scale;
    const signwidthHeight = `${fontSize * 2}px`;
    const numberWidthHeight = `${fontSize * 4.4}px`;

    return css`
      .price-wrapper {
        height: ${numberWidthHeight};
        width: ${numberWidthHeight};
      }

      .sign {
        height: ${signwidthHeight};
        width: ${signwidthHeight};
      }
    `;
  }}

  ${SignColors}
`;

const RoundNSquarePrice = styled.div`
  ${GeneralStyle}
  width: min-content;
  height: min-content;
  max-width: 100%;
  .sign {
    background-color: transparent !important;
    transform: translateX(-100%);
  }
  ${({ scale, styles }) => {
    const fontScale = styles.fontSize * scale;
    const topBottomPadding = `${fontScale / 4}px`;
    const leftRightPadding = `${2 * fontScale}px`;
    const topBottomBorderRadius = `${scale * 30}px`;

    return css`
      .price-wrapper {
        padding: ${topBottomPadding} ${leftRightPadding};
        border-top-right-radius: ${topBottomBorderRadius};
        border-bottom-left-radius: ${topBottomBorderRadius};
      }

      .sign {
        bottom: calc(${topBottomPadding}*2);
        left: ${leftRightPadding};
      }
    `;
  }}
`;

const RoughlyRoundedPrice = styled.div`
  ${GeneralStyle}

  .sign {
    top: 0;
    left: 0;
    transform: translate(50%, 50%);
    background-color: transparent !important;
  }

  ${({ scale, styles }) => {
    const fontScale = styles.fontSize * scale;
    const topBottomPadding = `${fontScale / 5}px`;
    const leftRightPadding = `${fontScale * 1.3}px`;
    const borderRadius = `${fontScale}px`;
    return css`
      .price-wrapper {
        border-radius: ${borderRadius};
        padding: ${topBottomPadding} ${leftRightPadding};
      }
    `;
  }}
`;
export default function Price({ price, unity, scale, styles, position }) {
  return (
    <Container className="flex justify-center align">
      {styles.priceStyle === ROUND_AND_SQUARE_PRICE_STYLE ? (
        <RoundNSquarePrice
          className="flex price"
          scale={scale}
          styles={styles}
          position={position}
        >
          <PriceContent
            price={price}
            styles={styles}
            unity={unity}
            scale={scale}
          />
        </RoundNSquarePrice>
      ) : styles.priceStyle === ROUNDED_PRICE_STYLE ? (
        <RoundedPrice
          className="flex price"
          scale={scale}
          styles={styles}
          position={position}
        >
          <PriceContent
            price={price}
            styles={styles}
            unity={unity}
            scale={scale}
          />
        </RoundedPrice>
      ) : styles.priceStyle === ROUGHLY_ROUNDED_PRICE_STYLE ? (
        <RoughlyRoundedPrice
          className="flex price"
          scale={scale}
          styles={styles}
          position={position}
        >
          <PriceContent
            price={price}
            styles={styles}
            unity={unity}
            scale={scale}
          />
        </RoughlyRoundedPrice>
      ) : (
        <DefaultPrice
          className="flex price"
          scale={scale}
          styles={styles}
          position={position}
        >
          <PriceContent
            price={price}
            styles={styles}
            unity={unity}
            scale={scale}
          />
        </DefaultPrice>
      )}
    </Container>
  );
}

const PriceContent = ({ price, styles, unity, scale }) => {
  return (
    <>
      {" "}
      <div className="price-wrapper">
        <span className="sign">R$</span>
        <div className="number-wrapper">
          <span className="integer">{price && price.slice(0, -3)}</span>
          <div className="decimal-unity-wrapper">
            <span className="decimal">
              {price && price.slice(-3).replace(".", ",")}
            </span>
            <Measurement
              fontSize={styles.fontSize}
              color={styles.txtColor}
              scale={scale}
            >
              {unity}
            </Measurement>
          </div>
        </div>
      </div>
    </>
  );
};
