import styled, { css } from "styled-components";

export const Input = styled.input`
  padding: 1.2rem 1rem;
  height: 4.2rem;
  color: rgba(1, 1, 1, 0.8);
  border: 1px solid rgba(1, 1, 1, 0.5);
  &:focus-within {
    border: 1px solid #d97706;
    color: black;
  }
`;
