import React from "react";
import Logo from "../sharable/Logo";
import styled from "styled-components";
const Container = styled.div`
  height: 100vh;
  header {
    padding: 2rem 3rem;
    justify-content: start;
  }

  main {
    height: 100%;
    margin-top: 5.6rem;
    padding: 0 4rem;
    h1 {
      margin-bottom: 1.4rem;
    }
    p {
      text-align: left;
      font-size: 1.6rem;
      width: 80%;
    }

    p:first-child {
      margin-bottom: 4.2rem;
    }
  }

  a:link,
  a:visited {
    all: unset;
    color: #d97706;
    border-bottom: 1px solid #d97706;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export default function PlanBasic() {
  return (
    <Container className="flex direction-column">
      <header className="flex">
        <Logo showGoBackButton={true} path={"/planos"} />
      </header>
      <main className="flex min-gap direction-column">
        <p>
          Pensando na acessibilidade dos nossos serviços, estamos em processo de
          construção desse plano para OfertasExpress.
        </p>
        <h1>
          Qual a diferença entre OfertasExpress Plano Premium e OfertasExpress
          Plano Básico?
        </h1>
        <p>
          A única diferença é o banco de imagens, com o ofertas premium você tem
          a vantagem da busca por imagem no nosso banco de imagens, já com o
          plano básico nós iremos dispobilizar para nossos usuários um arquivo
          com imagens de centenas de produtos.
        </p>
        <p>
          O usuário do plano básico terá as imagens dos produtos em seu próprio
          celular ou computador, ou seja, na hora de montar o encarte as imagens
          deverão ser carregadas da sua própria galeria de imagens em seu
          celular ou computador.
        </p>
      </main>
    </Container>
  );
}
