import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Modal,
  Header,
  InputWrapper,
  TextLink,
} from "../SharableStyles/FormStyle";
import { ModalHeader, Step } from "../SharableStyles/FormStyle";
import OAuth from "../components/sharable/OAuth";
import Loading from "../components/sharable/Loading";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../components/sharable/Logo";
import { Input } from "../components/sharable/InputStyle";
import Button from "../components/sharable/ActionButton";

import Error from "../components/sharable/Error";
import { emailRegex, passwordRegex, validateField } from "../services/Utils";
import { setTokenCookie } from "../TokenCookie";
const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
const Container = styled.div`
  width: 100vw;
  height: min-content;
`;

export default function RegisterPage() {
  const [registering, setRegistering] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const navigate = useNavigate();

  const validateFields = () => {
    const nameValidation = validateField("nome", name, true, true, 4, 14);
    const emailValidation = validateField(
      "email",
      email,
      true,
      false,
      null,
      null,
      emailRegex
    );
    const passwordValidation = validateField(
      "senha",
      password,
      true,
      true,
      6,
      8,
      passwordRegex
    );

    let message =
      nameValidation.message +
      emailValidation.message +
      passwordValidation.message;

    if (password != passwordConfirmation)
      message += "\nAs senhas devem ser iguais\n";

    if (
      nameValidation.isValid &&
      emailValidation.isValid &&
      passwordValidation.isValid &&
      password === passwordConfirmation
    )
      return true;
    else {
      setErrorMessage(message);
      return false;
    }
  };
  const register = async () => {
    const body = {
      email: email,
      password: password,
      username: name,
    };
    const res = await fetch(`${BASE_URL}auth/basic/register`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.ok) {
      const token = await res.text();
      setTokenCookie(token);
      navigate("/encartes");
    } else if (res.status === 409) {
      setErrorMessage("Esse email já está sendo utilizado. Tente fazer login");
    }
  };
  return (
    <Container>
      <Header>
        <Logo navigable={true} />
      </Header>

      <Modal>
        {registering && <Loading />}
        <ModalHeader>
          <h1>registrar</h1>
          <p>Crie incríveis encartes que chamem atenção do seu cliente!</p>
        </ModalHeader>
        <InputWrapper>
          {errorMessage && <Error message={errorMessage} />}
          <Input placeholder="Nome" onInput={(e) => setName(e.target.value)} />
          <Input
            placeholder="Email"
            type="email"
            onInput={(e) => setEmail(e.target.value)}
          />
          <Input
            placeholder="Senha"
            type="password"
            onInput={(e) => setPassword(e.target.value)}
          />
          <Input
            placeholder="Confirmar senha"
            type="password"
            onInput={(e) => setPasswordConfirmation(e.target.value)}
          />
          <Button
            text={"Continuar"}
            $fullWidth
            action={() => {
              const isFieldsValid = validateFields();
              if (isFieldsValid) register();
            }}
          />
          <OAuth
            setErrorMessage={setErrorMessage}
            action={"register"}
            setLogging={setRegistering}
            message={
              "Registre em nosso App utilizando sua conta do Google! É fácil e rápido!"
            }
          />
          <TextLink>
            Já possui uma conta? <Link to="/entrar">Entre</Link>
          </TextLink>
        </InputWrapper>
      </Modal>
    </Container>
  );
}
