import React, { useState, useEffect, useContext } from "react";
import { MenuTitle, MenuDescription } from "./Toolbar";
import { IonIcon } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import styled, { css, keyframes } from "styled-components";
import { Select, Selector } from "../CanvasViewer/EncarteStyle";
import {
  arrowDownOutline,
  arrowUpOutline,
  syncOutline,
  trashOutline,
} from "ionicons/icons";
import ProductObj from "../../../OBJRep/Product";
import { EncarteContext } from "../../Views/wo";
import Loading from "../../sharable/Loading";
import { useOutletContext } from "react-router";
import Button from "../../sharable/ActionButton";
import { UNITYS } from "../CanvasViewer/EncarteStyleConstants";
import UploadButton from "../../sharable/UploadButton";
import { toDataURL } from "../../../services/Utils";
import { searchProductImages } from "../../../services/ProductImageService";
import { Image } from "../../sharable/EncarteProduct";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 8.4rem);
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ProductInput = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  padding: 1.4rem 0;
  column-gap: 1.4rem;
  row-gap: 1.4rem;

  .buttons {
    grid-column: 1/3;
    width: calc(100% - 3px);
  }
  & .name {
    grid-column: 1/3;
    align-self: end;
  }

  align-items: end;

  .icon {
    font-size: 1.8rem;
    transition: all 300ms easy-in-out;
  }

  .icon-wrapper {
    width: min-content;
    height: min-content;
    position: relative;
    cursor: pointer;
    gap: 0.6rem;
    padding: 0.2rem 0.4rem;

    span {
      font-size: 1.4rem;
      text-transform: capitalize;
    }
  }

  .icon-wrapper:hover {
    color: #dc2626;
  }
  .icon-wrapper:before {
    right: 0;
    top: 0;
    position: absolute;
    font-size: 1.2rem;
    transform: translate(120%, -20%);
    color: #fff;
    padding: 0.4rem 0.8rem;
    border-radius: 0px;
    opacity: 1;
  }

  .icon-wrapper:hover:before {
    opacity: 1;
  }
  .icon:hover {
  }
`;

const rotating = keyframes`
    to {
        transform: rotate(360deg);
    }
`;
export const BoxImage = styled.div`
  span {
    color: #fff !important;
  }
  position: relative;
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: #fff;
  color: black;
  align-items: center;
  justify-content: center;
  height: min-content;
  width: 100%;
  .loading-currentProductImages {
    animation: ${rotating} 1s linear infinite;
    color: #d97706;
    grid-column: 1/-1;
    display: inline-block;
  }
  .button {
    grid-column: 1/-1;
  }

  ${({ imagesCount }) => {
    const gridColumns = imagesCount < 4 ? imagesCount : 4;
    return css`
      grid-template-columns: repeat(${gridColumns}, 1fr);
    `;
  }}
`;

const Input = styled.input`
  padding: 1.2rem 0 1.2rem 1rem;
  text-transform: capitalize;
  height: 4.2rem;
  accent-color: #d97706;
`;

const LabelInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.6rem;
  font-size: 1.4rem;
  &.checkbox {
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
`;

const ProdutosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddProductButton = styled.button`
  all: unset;
  width: calc(100% - 3px);
  padding: 1.2rem 0;
  font-size: 1.4rem;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  gap: 0.8rem;
  border-radius: 5px;
  font-weight: bold;
  &:hover {
    background-color: #d97706;
    border-color: transparent;
    transition: background-color 300ms ease-in-out;
  }
  &.disable {
    position: relative;
    border: none;
    cursor: default;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      border: 1px solid #fff;
      top: 50%;
    }
  }

  &.disable:hover {
    background-color: transparent;
  }
`;

const NoImagesMessage = styled.p`
  font-size: 1.2rem;
  text-align: center;
  grid-column: 1/3;
`;

//COMPONENT VERIFIED
export default function InputProducts({ props }) {
  const [currentProductImages, setCurrentProductImages] = useState([]);
  const [currentProductName, setCurrentProductName] = useState("");
  const [currentProductId, setCurrentProductId] = useState(0);
  const [downloadingProductImage, setDownloadingProductImage] = useState(false);
  const [wasNoImageFound, setNoImageFound] = useState(false);
  const { token } = useOutletContext();

  const handleCurrentProductImageUpdate = (image) => {
    if (!image || !props.encarteProducts) return;
    setDownloadingProductImage(true);
    const arr = props.encarteProducts;
    console.log(image);
    if (image.url.includes("/resources/files")) {
      arr[currentProductId].imageURLTmp = image.displayUrl;
      arr[currentProductId].imageURL = image.url;
      props.updateEncarteProducts([...arr]);
      setDownloadingProductImage(false);
    } else {
      toDataURL(image.url).then((displayUrl) => {
        if (displayUrl === "") {
          alert(
            "Não foi possível realizar o download dessa imagem, por favor, selecione outra."
          );
          setCurrentProductImages(
            currentProductImages.filter((i) => i.url !== image.url)
          );
        }
        arr[currentProductId].imageURLTmp = displayUrl;
        arr[currentProductId].imageURL = image.url;
        props.updateEncarteProducts([...arr]);
        setDownloadingProductImage(false);
      });
    }
  };

  const updateCurrProdName = (name) => {
    setCurrentProductName(name);
  };

  const updateProductInfo = (id, info, value) => {
    const arr = [...props.encarteProducts];
    arr.forEach((p, i) => {
      if (i == id) {
        p[info] = value;
      }
    });
    props.updateEncarteProducts(arr);
  };

  const removeProductEl = (el) => {
    const id = Number(
      el.target.closest(".product").getAttribute("data-product-id")
    );
    const arr = props.encarteProducts.filter((p, i) => i != id);
    props.updateEncarteProducts(arr);
  };

  const createNewProduct = () => {
    const arr = [...props.encarteProducts];
    const pid = arr.length;
    arr.push(ProductObj(pid));
    props.updateEncarteProducts(arr);
  };

  return (
    <Container>
      {props.encarteProducts && (
        <>
          <MenuDescription>
            Dica: Adicione os produtos que irão chamar atenção dos seus clientes
            e fazer eles comprarem de você.
          </MenuDescription>
          <MenuTitle>Produtos</MenuTitle>
          <AddProductButton
            className={
              props.maxProducts <= props.encarteProducts.length ? "disable" : ""
            }
            onClick={() => {
              if (props.maxProducts <= props.encarteProducts.length) return;
              createNewProduct();
            }}
          >
            Adicionar produto <IonIcon icon={addOutline} size="large" />
          </AddProductButton>

          <ProdutosContainer>
            {props.encarteProducts.map((prod, i) => {
              return (
                <ProductInput
                  onChange={() => {
                    setCurrentProductName(prod.name);
                  }}
                  onClick={(e) => {
                    if (
                      currentProductId != i ||
                      currentProductName != prod.name
                    )
                      setCurrentProductImages([]);
                    setCurrentProductId(i);
                    setCurrentProductName(prod.name);
                  }}
                  data-product-id={i}
                  className="product"
                  key={`product-${i}`}
                >
                  <div
                    className="icon-wrapper flex align"
                    onClick={removeProductEl}
                  >
                    <span>remover</span>
                    <IonIcon icon={trashOutline} className="icon" />{" "}
                  </div>
                  <Input
                    value={prod.name}
                    className="name"
                    type="text"
                    placeholder="nome do produto"
                    onInput={(e) => {
                      updateProductInfo(i, "name", e.target.value);
                      updateCurrProdName(e.target.value);
                      setCurrentProductId(i);
                    }}
                  />
                  <LabelInputWrapper>
                    <Input
                      value={prod.price.replace(".", ",")}
                      data="valor"
                      placeholder="R$00,00"
                      type="text"
                      className="price"
                      onInput={(e) => {
                        updateProductInfo(i, "price", e.target.value);
                      }}
                    />
                  </LabelInputWrapper>
                  <LabelInputWrapper>
                    <Selector
                      data="unidade"
                      className="unidade"
                      options={UNITYS}
                      callback={(v) => {
                        updateProductInfo(i, "unityMeasurement", v);
                      }}
                      txt="valor por"
                      selected={prod.unityMeasurement}
                    />
                  </LabelInputWrapper>

                  <BoxImage
                    className="box"
                    imagesCount={currentProductImages.length}
                  >
                    {currentProductId === i ? (
                      currentProductImages !== undefined &&
                      currentProductImages.length > 0 ? (
                        downloadingProductImage ? (
                          <Loading
                            color="#fff"
                            $NoBgColor
                            txt={"Baixando imagem..."}
                          />
                        ) : (
                          currentProductImages.map((image, id) => (
                            <Image
                              onClick={() => {
                                handleCurrentProductImageUpdate(image);
                              }}
                              url={`${image.displayUrl}`}
                              height="9rem"
                              hover={true}
                              key={`image-${id}`}
                              backgroundSize="contain"
                            />
                          ))
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </BoxImage>
                  <NoImagesMessage>
                    {wasNoImageFound &&
                      `Nenhuma imagem foi encontrada para o produto "${currentProductName}"`}
                  </NoImagesMessage>
                  <div className="buttons flex direction-column min-gap">
                    <Button
                      color={"#fff"}
                      $fullWidth
                      size="1.4"
                      $NoBgColor
                      text={"Buscar imagens"}
                      action={async () => {
                        setNoImageFound(false);
                        if (
                          !currentProductName ||
                          currentProductName.length < 3 ||
                          currentProductName.toLocaleLowerCase() ===
                            "nome do produto"
                        )
                          return;
                        const imgs = await searchProductImages(
                          currentProductName,
                          token,
                          currentProductImages.length + 1
                        );
                        setCurrentProductImages(imgs);
                        if (imgs.length <= 0) setNoImageFound(true);
                      }}
                    />
                    <UploadButton
                      $fullWidth
                      size="1.4"
                      text={"Carregar da galeria"}
                      perform={(url) => {
                        const products = [...props.encarteProducts];
                        products[currentProductId].imageURLTmp = url;
                        products[currentProductId].imageURL = url;
                        props.updateEncarteProducts(products);
                      }}
                    />
                  </div>
                </ProductInput>
              );
            })}
          </ProdutosContainer>
        </>
      )}
    </Container>
  );
}
