import React from "react";
import styled from "styled-components";
import Logo from "../sharable/Logo";
import { Link } from "react-router-dom";
const Container = styled.div`
  height: 100vh;
  header {
    padding: 2rem 3rem;
    justify-content: start;
  }

  main {
    height: 100%;
    justify-content: center;
    p {
      text-align: center;
      font-size: 1.6rem;
      width: 100%;
    }
  }

  a:link,
  a:visited {
    all: unset;
    color: #d97706;
    border-bottom: 1px solid #d97706;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export default function NotFoundView() {
  return (
    <Container className="flex direction-column">
      <header className="flex">
        <Logo />
      </header>
      <main className="flex min-gap direction-column">
        <p>
          Essa pagina não existe! Ir para{" "}
          <Link to={"/encartes"}>Página inicial</Link>
        </p>
        <p>
          {" "}
          O problema ainda persiste? Tente <Link to={"/entrar"}>Entrar</Link> na
          sua conta novamente ou nos chame no{" "}
          <a href="https://wa.me/message/2GPGFCP27PUCD1">Whatsapp</a> para
          resolver seu problema!
        </p>
      </main>
    </Container>
  );
}
