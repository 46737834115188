import { IonIcon } from "@ionic/react";
import React, { useEffect } from "react";
import { syncOutline } from "ionicons/icons";
import styled, { css, keyframes } from "styled-components";
const rotating = keyframes`
    to {
        transform: rotate(360deg) ;
    }
`;
const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${({ size }) => css`
    font-size: ${size}rem;
  `}

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.7);
  .icon {
    color: black;
    font-size: 2.4rem !important;
    left: 50%;
    animation: ${rotating} 1.2s linear infinite;
    ${({ color }) =>
      color &&
      css`
        color: ${color} !important;
      `}
  }
  ${({ $NoBg }) =>
    $NoBg &&
    css`
      background-color: transparent !important;
    `}
  span {
    font-size: 1.4rem !important;
  }
`;

export default function Loading({ size, color, txt, $NoBg }) {
  return (
    <IconWrapper
      size={size}
      color={color}
      className="flex min-gap"
      $NoBg={$NoBg}
    >
      <IonIcon className="icon" icon={syncOutline} />
      {txt ? <span>{txt}</span> : ""}
    </IconWrapper>
  );
}
