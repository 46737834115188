import React, { useEffect, useState } from "react";
import { MenuDescription, MenuTitle } from "./Toolbar";
import styled from "styled-components";
import { Input } from "../../sharable/InputStyle";
import Switch from "../../sharable/Switch";

const Container = styled.div`
  main {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
`;

const LabelInputWrapper = styled.div`
  label {
    font-size: 1.6rem;
  }
`;
export default function DateMenuView({ props }) {
  const [startDate, setStartDate] = useState(props.encarte.startDate);
  const [endDate, setEndDate] = useState(props.encarte.endDate);
  const [hasStartDate, setHasInitalDate] = useState(
    props.encarte.startDate ? true : false
  );
  const [hasEndDate, setHasEndDate] = useState(
    props.encarte.endDate ? true : false
  );

  const handleHasEndDateChange = useEffect(() => {
    if (!hasEndDate) handleDateChange("endDate", null);
    else handleDateChange("endDate", endDate);
  }, [hasEndDate]);

  const handleHasStartDateChange = useEffect(() => {
    if (!hasStartDate) handleDateChange("startDate", null);
    else handleDateChange("startDate", startDate);
  }, [hasStartDate]);

  const handleDateChange = (prop, value) => {
    const copy = { ...props.encarte };
    copy[prop] = value;
    props.updateEncarte(copy);
  };
  return (
    <Container>
      <MenuDescription>
        Selecione o período em que a oferta ficará válida.
      </MenuDescription>
      <MenuTitle>Datas</MenuTitle>

      <main>
        <LabelInputWrapper className="flex min-gap direction-column">
          <div className="label-switch flex min-gap align">
            <label htmlFor="start">Início da oferta</label>
            <Switch on={hasStartDate} callback={setHasInitalDate} />
          </div>
          <Input
            className="startDate"
            type="date"
            id="start"
            lang="pt-BR"
            defaultValue={dateAsValue(startDate)}
            onInput={(e) => {
              setStartDate(new Date(e.target.value));
              handleDateChange("startDate", new Date(e.target.value));
            }}
            readOnly={!hasStartDate}
          />
        </LabelInputWrapper>
        <LabelInputWrapper className="flex min-gap direction-column">
          <div className="label-switch flex min-gap align">
            <label htmlFor="end">Fim da oferta</label>
            <Switch on={hasEndDate} callback={setHasEndDate} />
          </div>
          <Input
            className="endDate"
            type="date"
            id="end"
            lang="pt-BR"
            defaultValue={dateAsValue(endDate)}
            onChange={(e) => {
              setEndDate(new Date(e.target.value));
              handleDateChange("endDate", new Date(e.target.value));
            }}
            readOnly={!hasEndDate}
          />
        </LabelInputWrapper>
      </main>
    </Container>
  );
}

const dateAsValue = (date) => {
  if (!date) return;
  const month =
    date.getUTCMonth() + 1 < 10
      ? `0${date.getUTCMonth() + 1}`
      : date.getUTCMonth() + 1;
  const day =
    date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();
  const year = date.getFullYear();
  const s = `${year}-${month}-${day}`;

  return s;
};
