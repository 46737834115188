import React from "react";
import styled from "styled-components";
import Logo from "../sharable/Logo";
import Button from "../sharable/ActionButton";
import { useNavigate } from "react-router";
import { devices } from "../../Devices";
import Plans from "../sharable/Plans";

const Container = styled.div`
  padding: 2rem 3.6rem;
  height: 110vh;
  .plans-wrapper {
    gap: 4.2rem;
    height: 100%;
    justify-content: center;
    align-items: center;
    @media ${devices.tablet} {
      flex-direction: column;
      gap: 7.2rem;
    }
  }
  header {
    justify-content: start;
  }

  .title {
    font-size: 3rem;
    text-align: center;
    margin-top: 4.2rem;
    font-weight: 600;
    @media ${devices.tablet} {
      font-size: 2.6rem;
      line-height: 1.2;
      margin-bottom: 2rem;
    }
  }
`;

//COMPONENT VERIFIED 31/05;
export default function PaymentPlansView() {
  const navigate = useNavigate();
  return (
    <Container className="flex direction-column">
      <header className="flex">
        <Logo showGoBackButton={true} path="/encartes" />
      </header>
      <Plans title={"Escolha seu plano de assinatura"} />
    </Container>
  );
}
