import React from "react";
import styled from "styled-components";
import LogoInput from "../../sharable/LogoInput.jsx";
import { MenuTitle, MenuDescription } from "./Toolbar.jsx";
import MarcaForm from "../../sharable/MarcaForm.jsx";
import { useOutletContext } from "react-router";
const Container = styled.div`
  height: calc(100vh - 8.4rem);
  overflow-y: auto;
  overflow-x: hidden;
`;

//COMPONENT VERIFIED
export default function BrandMenuView({ props }) {
  return (
    <Container>
      <MenuDescription>
        Adicione suas informações de contato e endereço. Caso queira omitir
        alguma informação apenas deixe em branco!
      </MenuDescription>
      <MenuTitle>sua marca</MenuTitle>
      <MarcaForm
        updateBrand={props.updateBrand}
        brand={props.brand}
        flex="column"
      />
    </Container>
  );
}
